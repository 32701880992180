import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function AffUserUpdate() {
  const [userName, setUserName] = useState();
  const [userID, setUserID] = useState();
  const [userEmail, setUserEmail] = useState();
  const { UserMobile } = useParams();
  const MySwal = withReactContent(Swal);
  const token = localStorage.getItem("userToken");
  const [name, setName] = useState();
  const [password, setPassword] = useState("");
  const [id_no, setIDNo] = useState();
  const [id_no_bool, setid_no_bool] = useState(false);
  const [name_bool, setname_bool] = useState(false);
  const [password_bool, setpassword_bool] = useState(false);

  useEffect(() => {
    Axios.post(
      "https://us-central1-chillimbasaffe.cloudfunctions.net/getalluserinfo",
      { message: { access_token: token, mobile_no: UserMobile } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting Users Data ::", res.data);
        setUserName(res.data.data.name);
        setName(res.data.data.name);
        setUserID(res.data.data.id_no);
        setIDNo(res.data.data.id_no);
        setUserEmail(res.data.data.email);
      })
      .catch((err) => console.log(err));
  }, []);

  const updateUser = (e) => {
    e.preventDefault();

    if (id_no && name && password) {
      Axios.post(
        "https://us-central1-chillimbasaffe.cloudfunctions.net/updateuser",
        {
          message: {
            mobile_no: UserMobile,
            id_no: id_no,
            name: name,
            password: password,
            role: "",
            id_no_bool: id_no_bool,
            name_bool: name_bool,
            password_bool: password_bool,
            role_bool: false,
            access_token: token,
          },
        }
      )
        .then((res) => {
          MySwal.fire({
            position: "center",
            icon: "success",
            title: "Updated Successfully",
            text: "Refresh to see Changes!",
          });
          //   console.log("Posting Data From::", res.data);
        })
        .catch((err) => {
          MySwal.fire({
            position: "center",
            icon: "error",
            title: " Error while Updating",
            text: "Check Your Fields!",
          });
          console.log(err.message);
        });
    } else {
      MySwal.fire({
        position: "center",
        icon: "error",
        title: "All Fields are required!",
        text: "Error while Updating",
      });
    }
  };

  return (
    <>
      <main className="pt-6 md:pt-20 -mt-2 md:m-5 m-2">
        <div className="mx-auto md:p-2">
          <div className="flex-shrink max-w-full md:px-4 w-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden pb-8">
              <div className="h-40 overflow-hidden relative">
                <img
                  src={require("../../img/bg-profile.jpg")}
                  className="w-full"
                  alt="background"
                />
              </div>
              <div className="text-center ">
                <div className="relative flex justify-center -mt-10">
                  <img
                    src={require("../../img/avatar8.png")}
                    className="rounded-full w-24  bg-gray-200 border-solid border-white border-2 -mt-3"
                    alt="profile"
                  />
                </div>

                <div className="md:px-6 py-3 text-xl border-b dark:border-gray-800 font-bold dark:text-gray-300 font-andika">
                  Update Member Profile
                </div>
              </div>
            </div>
          </div>

          <div className="flex mt-4 flex-wrap flex-row">
            <div className="flex-shrink mx-auto max-w-full md:px-4 w-full lg:w-2/3 mb-6">
              <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                <div class="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                  <div class="flex items-center">
                    <h4 class="dark:text-white font-semibold my-3">
                      Edit Profile
                    </h4>
                    <button
                      onClick={updateUser}
                      type="button"
                      class="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
                <div class="flex-auto p-6">
                  <div class="flex flex-wrap -mx-3">
                    <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                      <div class="mb-4">
                        <label
                          for="username"
                          class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                        >
                          Username
                        </label>
                        <input
                          type="text"
                          name="username"
                          class="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                            setname_bool(true);
                          }}
                        />
                      </div>
                    </div>
                    <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                      <div class="mb-4">
                        <label
                          for="email"
                          class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                        >
                          ID No.
                        </label>
                        <input
                          type="text"
                          name="id_no"
                          class="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                          value={id_no}
                          onChange={(e) => {
                            setIDNo(e.target.value);
                            setid_no_bool(true);
                          }}
                        />
                      </div>
                    </div>

                    <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                      <div class="mb-4">
                        <label
                          for="last name"
                          class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 dark:text-white/80"
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          class="focus:shadow-primary-outline dark:bg-slate-850 dark:text-white text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setpassword_bool(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <hr class="h-px mx-0 my-4 bg-transparent border-0 opacity-25 bg-gradient-to-r from-transparent via-black/40 to-transparent dark:bg-gradient-to-r dark:from-transparent dark:via-white dark:to-transparent " />
                </div>
              </div>
            </div>
            <div className="flex-shrink max-w-full md:px-4 w-full lg:w-1/3 mb-6">
              <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl dark:bg-slate-850 dark:shadow-dark-xl rounded-2xl bg-clip-border">
                <img
                  class="w-full rounded-t-2xl"
                  src={require("../../img/bg-profile.jpg")}
                  alt="profile"
                />
                <div class="flex flex-wrap justify-center -mx-3">
                  <div class="w-4/12 max-w-full px-3 flex-0 ">
                    <div class="mb-6 -mt-6 lg:mb-0 lg:-mt-16">
                      <img
                        class="h-auto max-w-full border-2 border-white border-solid rounded-circle"
                        src={require("../../img/avatar8.png")}
                        alt="profile"
                      />
                    </div>
                  </div>
                </div>

                <h1 class="ml-2 text-gray-800 dark:text-gray-100 font-akaya my-3 text-xl">
                  Personal Details
                </h1>

                <div class="content-header flex justify-between items-center my-3 md:px-4 font-andika">
                  <h1 class="font-bold text-gray-800 dark:text-gray-100">
                    <span class="px-3 py-2 inline-block  md:mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500">
                      <i class="fas fa-user"></i>
                    </span>{" "}
                    &nbsp;Name
                  </h1>
                  <span class="dark:text-gray-100">{userName}</span>
                </div>

                <div class="content-header flex justify-between items-center my-3 md:px-4 font-andika">
                  <h1 class="font-bold text-gray-800 dark:text-gray-100">
                    <span class="px-3 py-2 inline-block  md:mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500">
                      <i class="fas fa-address-book"></i>
                    </span>{" "}
                    &nbsp;National ID
                  </h1>
                  <span class="dark:text-gray-100">{userID}</span>
                </div>

                <div class="content-header flex justify-between items-center my-3 md:px-4 font-andika">
                  <h1 class="font-bold text-gray-800 dark:text-gray-100">
                    <span class="px-3 py-2 inline-block  md:mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500">
                      <i class="fas fa-phone"></i>
                    </span>{" "}
                    &nbsp;Phone
                  </h1>
                  <span class="dark:text-gray-100">+{UserMobile}</span>
                </div>

                <div class="content-header flex justify-between items-center my-3 md:px-4 font-andika">
                  <h1 class="font-bold text-gray-800 dark:text-gray-100">
                    <span class="px-3 py-2 inline-block  md:mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500">
                      <i class="fas fa-envelope-open"></i>
                    </span>{" "}
                    &nbsp;Email
                  </h1>
                  <span class="dark:text-gray-100">{userEmail}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default AffUserUpdate;
