import React from "react";

// Modify path,title, icons, subnav according to project
export const sidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <i className="fa-solid fa-home"></i>,
  },
  {
    title: "Saccos",
    path: "#",
    icon: <i className="fa-solid fa-bookmark"></i>,
    subNav: [
      {
        title: "All Saccos",
        path: "/sgh",
        icon: <i className="fa-solid fa-users"></i>,
      },
      {
        title: "Add New Sacco ",
        path: "/addShg",
        icon: <i className="fa-solid fa-user-plus"></i>,
      },
      {
        title: "Saccos Savings",
        path: "/shgSavings",
        icon: <i className="fa-solid fa-sack-dollar"></i>,
      },
    ],
  },

  {
    title: "Loans",
    path: "#",
    icon: <i className="fas fa-hand-holding-usd"></i>,
    subNav: [
      {
        title: "All Loans",
        path: "/allLoans",
        icon: <i className="fa-solid fa-sack-dollar"></i>,
      },
      {
        title: "Paid Loans ",
        path: "/paidLoans",
        icon: <i className="fa-solid fa-user-plus"></i>,
      },
      {
        title: "Unpaid Loans",
        path: "/unpaidLoans",
        icon: <i className="fa-solid  fa-user-shield"></i>,
      },
    ],
  },

  {
    title: "Users",
    path: "#",
    icon: <i className="fa-solid fa-user"></i>,
    subNav: [
      {
        title: " Users",
        path: "/user",
        icon: <i className="fa-solid fa-users"></i>,
      },
      {
        title: " Add Members",
        path: "/addMembers",
        icon: <i className="fa-solid fa-users"></i>,
      },

      {
        title: "Add Affiliate",
        path: "/userCreateAff",
        icon: <i className="fa-solid fa-user-shield"></i>,
      },
      {
        title: "Link Users to Sacco",
        path: "/userLinkShg",
        icon: <i className="fa-solid fa-sign-out-alt"></i>,
      },
      {
        title: "Add SubAdmin",
        path: "/userCreate",
        icon: <i className="fa-solid fa-user"></i>,
      },
    ],
  },
];
