import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { authContext } from "../../Context/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function UserSelectSHG() {
  const MySwal = withReactContent(Swal);
  // const [shg, setSHG] = useState([]);
  const [dispalyshg, setdisplaySHG] = useState([]);
  const [allErr, setallErr] = useState(false);
  const mobile_no = localStorage.getItem("userMobile");
  const token = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const { setLogged } = useContext(authContext);
  const { setUserName } = useContext(authContext);
  const { setUserMobile } = useContext(authContext);
  const { setUserID } = useContext(authContext);
  const [loading, setLoading] = useState(false);

  const [userSHG, setUserSHG] = useState("");

  useEffect(() => {
    Axios.post(
      "https://us-central1-chillimbasaffe.cloudfunctions.net/getmemberaccounts",
      { message: { access_token: token, mobile_no: mobile_no } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting DAta From::", res.data.data);
        const shgggs = res.data.data;
        const shgarray = Object.values(shgggs);
        setUserSHG(shgarray[0].account_name);
        setdisplaySHG(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const myshg = Object.values(dispalyshg);
  const dataSHG = myshg.map((data, index) => {
    return (
      <option key={index} value={data.account_name}>
        {data.account_name}
      </option>
    );
  });

  const logintoSHG = (e) => {
    e.preventDefault();
    setLoading(true);
    if (userSHG) {
      setUserSHG(userSHG);
      localStorage.setItem("userSHG", userSHG);
      Axios.post(
        "https://us-central1-chillimbasaffe.cloudfunctions.net/getallmembersinSHG",
        { message: { access_token: token, account_name: userSHG } },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((res) => {
          // console.log("GEtting Users Data ::", res.data.data);
          const values = Object.values(res.data.data);
          // console.log(values);
          values.map((data, index) => {
            if (data.mobile_no == mobile_no) {
              // console.log(data.role);
              localStorage.setItem("userRole", data.role);
              setLogged(true);
              setUserName(data.name);
              setUserMobile(data.mobile_no);
              setUserID(data.id_no);
              // setUserRole(res.data.data.role);
              setLoading(false);

              if (data.role === "chairman") {
                navigate("/chairDashboard");
              } else if (data.role === "secretary") {
                navigate("/secDashboard");
              } else if (data.role === "treasurer") {
                navigate("/tresDashboard");
              } else if (data.role === "user") {
                MySwal.fire({
                  position: "center",
                  icon: "error",
                  title: " You Cannot Login as A Member!",
                  text: "You need to be an Official to the Selected SHG to Login!",
                });
                localStorage.removeItem("userMobile");
                localStorage.removeItem("userRole");
                localStorage.removeItem("userSHG");
                localStorage.removeItem("userToken");
                setLogged(false);
                setUserName(undefined);
                setUserMobile(undefined);
                setUserID(undefined);
                navigate("/signIn");
              }
            }
          });
        })
        .catch((err) => {
          alert(err);
          setLoading(false);
        });
      // navigate("/userDashboard");
    } else {
      setallErr(true);
    }
  };

  return (
    <>
      <main className="pt-20 -mt-2">
        <div
          className={`${
            !loading && "hidden"
          } w-full h-full fixed  top-0 left-0 bg-white opacity-75 z-50`}
        >
          <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
            <i className="fas fa-circle-notch fa-spin fa-5x"></i>
          </span>
        </div>
        <div className="mx-auto p-2">
          <div className="flex flex-wrap flex-row">
            <div className="flex-shrink max-w-full px-4 w-full">
              <p className="text-xl font-bold mt-3 mb-5 dark:text-gray-200">
                Choose Sacco
              </p>
            </div>
            <div className="flex-shrink mx-auto max-w-full px-4 w-full lg:w-2/3 mb-6">
              <div
                className={`${
                  !allErr && "hidden"
                } bg-orange-100 my-5    border-t-4 border-orange-500 rounded-b text-orange-900 px-4 py-3 shadow-md`}
                role="alert"
              >
                <div className="flex">
                  <div className="py-1">
                    <svg
                      className="fill-current h-6 w-6 text-orange-500 mr-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </div>
                  <div>
                    <p className="font-bold">
                      Error Occured While Choosing Sacco
                    </p>
                    <p className="text-sm">* Sacco Required</p>
                  </div>
                </div>
              </div>
              <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg mb-6">
                <div className="flex-1 p-6 bg-gray-100 dark:bg-gray-900 bg-opacity-60 dark:bg-opacity-20">
                  <form className="flex flex-wrap flex-row -mx-4">
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                      <label className="inline-block mb-2 dark:text-gray-200">
                        SGH Name
                      </label>

                      <select
                        name="shg"
                        id="inputshg"
                        className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 dark:text-gray-200 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                        value={userSHG}
                        aria-label="role"
                        onChange={(e) => {
                          setUserSHG(e.target.value);
                          setallErr(false);
                        }}
                      >
                        {dataSHG}
                      </select>
                    </div>

                    <div className="flex-shrink max-w-full px-4 w-full">
                      <button
                        type="submit"
                        onClick={logintoSHG}
                        className=" text-center mb-3 rounded py-2 px-5 inline-block   leading-5 text-gray-100 bg-indigo-500 bg-transparent border border-indigo-500 hover:text-gray-100 hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0"
                      >
                        Login to Sacco +
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default UserSelectSHG;
