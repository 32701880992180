import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";

function SecDashboard() {
  const [totalUsers, setTotalUsers] = useState([]);
  const secSHG = localStorage.getItem("userSHG");
  const token = localStorage.getItem("userToken");

  const [savings, setSavings] = useState([]);
  useEffect(() => {
    Axios.post(
      "https://us-central1-chillimbasaffe.cloudfunctions.net/getallsavings",
      { message: { access_token: token } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting DAta From::", res.data.grouped_savings);
        setSavings(res.data.grouped_savings);
      })
      .catch((err) => console.log(err));
  }, []);

  const mySavings = Object.values(savings);
  const dataSavings = mySavings.map((data, index) => {
    if (data.account_name == secSHG) {
      return <span key={index}>{data.amount}</span>;
    }
  });

  useEffect(() => {
    Axios.post(
      "https://us-central1-chillimbasaffe.cloudfunctions.net/statistics",
      { message: { access_token: token, option: "totalnouserShg" } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting DAta From::", res.data.data);
        setTotalUsers(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const myTotalUsers = Object.values(totalUsers);
  const dataUsers = myTotalUsers.map((data, index) => {
    if (data.accountname == secSHG) {
      return <span key={index}>{data.no_of_members}</span>;
    }
  });

  return (
    <>
      <div className="body-content px-8 py-8 ">
        <div className="flex justify-between items-end flex-wrap">
          <div className="page-title mb-7">
            <h3 className="mb-0 text-4xl">Dashboard</h3>
            <p className="text-textBody m-0">Welcome to your dashboard</p>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6 mb-6">
          <Link
            to={"/secShgSavings"}
            className="hover:scale-105 transition ease-in-out delay-150"
          >
            <div className="widget-item bg-white p-6 flex justify-between rounded-md">
              <div>
                <div>
                  <h1 className="text-xl leading-4 font-semibold mb-5">
                    Total Group Savings
                  </h1>
                  <h1 className="text-4xl   font-bold text-slate-700 my-3 leading-none">
                    <span className="opacity-0">0</span>
                    {dataSavings}
                  </h1>

                  <div className="badge space-x-1 text-xl text-info bg-info/10">
                    {" "}
                    <span>Kwacha</span>
                    <i className="fas fa-arrow-right"></i>
                  </div>
                  <p className="text-md leading-4 mt-3">Savings</p>
                </div>
              </div>
              <div>
                <span className="text-lg text-white rounded-full flex items-center justify-center h-12 w-12 shrink-0 bg-info">
                  <svg
                    width="20"
                    height="22"
                    viewBox="0 0 20 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 21H19"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.59998 7.37988H2C1.45 7.37988 1 7.82988 1 8.37988V16.9999C1 17.5499 1.45 17.9999 2 17.9999H3.59998C4.14998 17.9999 4.59998 17.5499 4.59998 16.9999V8.37988C4.59998 7.82988 4.14998 7.37988 3.59998 7.37988Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.7999 4.18994H9.19995C8.64995 4.18994 8.19995 4.63994 8.19995 5.18994V16.9999C8.19995 17.5499 8.64995 17.9999 9.19995 17.9999H10.7999C11.3499 17.9999 11.7999 17.5499 11.7999 16.9999V5.18994C11.7999 4.63994 11.3499 4.18994 10.7999 4.18994Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.9999 1H16.3999C15.8499 1 15.3999 1.45 15.3999 2V17C15.3999 17.55 15.8499 18 16.3999 18H17.9999C18.5499 18 18.9999 17.55 18.9999 17V2C18.9999 1.45 18.5499 1 17.9999 1Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </Link>

          <Link
            to={"/secMembers"}
            className="hover:scale-105 transition ease-in-out delay-150"
          >
            <div className="widget-item bg-white p-6 flex justify-between rounded-md">
              <div>
                <h1 className="text-xl leading-4 font-semibold mb-5">
                  Total Members
                </h1>
                <h1 className="text-4xl   font-bold text-slate-700 my-3 leading-none">
                  <span className="opacity-0">0</span>
                  {dataUsers}
                </h1>

                <div className="badge space-x-1 text-xl">
                  {" "}
                  <span>No.</span>
                  <i className="fas fa-arrow-right"></i>
                </div>
                <p className="text-md leading-4 mt-3">Sacco</p>
              </div>
              <div>
                <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-blue-500 to-violet-500">
                  <i className="fas fa-users leading-none  text-lg relative top-3.5 text-white"></i>
                </div>{" "}
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default SecDashboard;
