import React, { useState } from "react";
import { Link } from "react-router-dom";

function Terms() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <>
      <header className="md:py-4 bg-black md:py-6 sticky top-0 z-40">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            <div className="shrink-0">
              <img
                className="w-auto h-9"
                src={require("../img/logow.png")}
                alt=""
              />
            </div>

            <div className="flex md:hidden">
              <button onClick={handleOpen} type="button" className="text-white">
                <span aria-hidden="true" className={`${open && "hidden"} px-1`}>
                  <svg
                    className="w-7 h-7"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </span>

                <span
                  aria-hidden="true"
                  className={`${!open && "hidden"} px-1`}
                >
                  <span className="w-7 h-7">
                    <i className="fas fa-close"></i>
                  </span>
                </span>
              </button>
            </div>

            <nav className="hidden ml-10 mr-auto space-x-10 lg:ml-20 lg:space-x-12 md:flex md:items-center md:justify-start  ">
              <Link
                to="/"
                className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
              >
                {" "}
                Home{" "}
              </Link>
            </nav>

            <div className="relative hidden md:items-center md:justify-center md:inline-flex group">
              <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>
              <Link
                to="/signIn"
                className="relative inline-flex items-center justify-center px-6 py-2 text-base font-normal text-white bg-black border border-transparent rounded-full"
                role="button"
              >
                {" "}
                Sign In{" "}
              </Link>
            </div>
          </div>

          <nav className={`${!open && "hidden"} md:hidden`}>
            <div className="flex flex-col pt-8 pb-4 space-y-6">
              <Link
                to="/"
                className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
              >
                {" "}
                Home{" "}
              </Link>
              <div className="relative inline-flex items-center justify-center group">
                <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>
                <Link
                  to="/signIn"
                  title=""
                  className="relative inline-flex items-center justify-center w-full px-6 py-2 text-base font-normal text-white bg-black border border-transparent rounded-full"
                  role="button"
                >
                  {" "}
                  Sign In{" "}
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </header>

      <main id="content" role="main">
        <div class="md:max-w-screen-sm text-center px-4 sm:px-6 lg:px-8 pt-24 pb-6 mx-auto">
          <h1 class="text-2xl font-bold md:text-4xl dark:text-white">
            Terms &amp; Conditions
          </h1>
        </div>

        <div class="md:max-w-screen-sm lg:max-w-[992px] px-4 sm:px-6 lg:px-8 pb-12 md:pt-6 sm:pb-20 mx-auto dark:text-slate-300">
          <div class="grid gap-4 md:gap-8">
            <div>
              <p class="mb-5 dark:text-gray-400">
                Please read and understand these Terms of Service (“Terms”)
                carefully. These Terms replace all earlier General Terms and
                Conditions and apply to all Maano Digi Save users who agree to
                and start on or after{" "}
                <span className="font-bold">May 1, 2023</span> (“Effective
                Date”). For Maano Digi Save users who started before the
                Effective Date and are still ongoing, the Terms that were in
                place when the Maano Digi Save usage began remaining in effect.
              </p>

              <p class="dark:text-gray-400">
                Msimbo Integrated Technologies Limited, a company incorporated
                under the laws of the Republic of Kenya (“Msimbo”), has the
                rights of use the brand name “Maano Digi Save” as well as its
                accompanying logo wherein used here. All rights thereto are
                reserved.
              </p>
            </div>

            <div>
              <ol className="list-decimal">
                <li className="mb-6">
                  By visiting or using any Maano Digi Save website, App, USSD
                  service, portal or extranet (“Platform”), or the services
                  provided on any Maano Digi Save Platform, you agree to these
                  Terms of Use.{" "}
                </li>
                <li className="mb-6">
                  If you are a Maano Digi Save customer, you are also bound by
                  the Maano Digi Save’s relevant Terms and Conditions of service
                  Agreement and any other terms that apply to your service. If
                  any provision of these Terms of Use conflicts with any term in
                  the Terms and Conditions of service, the Terms and Conditions
                  of usage will prevail to the extent of such inconsistency.{" "}
                </li>
                <li className="mb-6">
                  If you are a Maano Digi Save service provider, dealer, agent,
                  consultant, contractor, or an employee of any of these
                  entities, your use of the Platform may also be subject to your
                  binding agreement with Maano Digi Save, and if any provision
                  of these Terms of Use conflicts with any term in your
                  agreement with Maano Digi Save, the term of your agreement
                  with Maano Digi Save prevails to the extent of such
                  inconsistency.{" "}
                </li>
                <li className="mb-6">
                  Please read these Terms of Use, together with our Privacy
                  Policy and Terms and Conditions of service carefully, they
                  will govern our relationship with you in relation to your use
                  of the Platform and will constitute an ongoing binding
                  agreement between Maano Digi Save and yourself.{" "}
                </li>
              </ol>
            </div>

            <div>
              <h2 class="text-lg sm:text-xl font-semibold mb-2 dark:text-white">
                INTERPRETATION
              </h2>

              <p class="dark:text-gray-400">
                <span className="font-bold">"Administrative User"</span> means
                an individual who is an employee or contractor of Msimbo
                Integrated Technologies Limited and has exclusive access to all
                controls of Maano Digi Save backend for onboarding and support
                purposes.
              </p>
            </div>

            <div>
              <p class="dark:text-gray-400">
                <span className="font-bold">“Content” </span>
                means all material, information, access, and details contained
                in the Maano Digi Save Website and all or any material generated
                on the Maano Digi Save Website.
              </p>
            </div>

            <div>
              <p class="dark:text-gray-400">
                <span className="font-bold">“Customer” </span>
                means a Group, Sacco, Corporate or Individual user of the Maano
                Digi Save Website who is fully paid up and in use of Maano Digi
                Save services.
              </p>
            </div>
            <div>
              <p class="dark:text-gray-400">
                <span className="font-bold">“Services” </span>
                means the services provided by Maano Digi Save to the Customer
                that helps the micro-savings, micro-lending, balance querying,
                statement requests, and PIN changes via USSD, Mobile App and
                Website, as more particularly described on the Maano Digi Save
                Website.
              </p>
            </div>
            <div>
              <p class="dark:text-gray-400">
                <span className="font-bold">“User” </span>
                means any person (group, Sacco, sacco or individual) who
                accesses and makes use of the Maano Digi Save Website, USSD or
                Mobile App regardless of whether such User is a Customer of
                Maano Digi Save.
              </p>
            </div>
            <div>
              <p class="dark:text-gray-400">
                Clause headings do not affect the interpretation of these terms.
              </p>
            </div>
            <div>
              <p class="dark:text-gray-400">
                A reference to a person includes a natural person, a corporate
                or unincorporated body (whether having a separate legal
                personality).
              </p>
            </div>
            <div>
              <p class="dark:text-gray-400">
                A reference to a particular law is a reference to it as it is in
                force for the time being taking account of any amendment,
                extension, application, or re-enactment, and includes any
                subordinate legislation for the time being in force made under
                it.
              </p>
            </div>
            <div>
              <p class="dark:text-gray-400">
                Words in the singular include the plural and, in the plural,
                include the singular.
              </p>
            </div>

            <div>
              <h2 class="text-lg sm:text-xl font-semibold mb-2 dark:text-white">
                ACCEPTANCE
              </h2>
              <ol className="list-decimal">
                <li className="mb-6">
                  You agree to be bound by the terms of this Agreement and our
                  Privacy Policy or any other document that may be provided from
                  time to time if you continue to browse and use this Platform,
                  USSD and Mobile App.{" "}
                </li>
                <li className="mb-6">
                  You shall not use this Platform, USSD and Mobile App or enter
                  a contract with us if you have not met the following criteria:{" "}
                  <ul class="list-disc">
                    <li>You must be of legal age.</li>
                    <li>
                      You must be allowed to receive these services under the
                      laws of the country/region in which you use the services.
                    </li>
                  </ul>
                </li>
                <li className="mb-6">
                  Whenever the need arises, we may request you to enter into a
                  separate agreement with us or our affiliates for any Service
                  (hereinafter referred to as an “Additional Agreement”). In
                  case of any inconsistency or conflict between these Terms and
                  any Additional Agreement, the Additional Agreement shall take
                  precedence over the Terms but only in relation to the specific
                  Service being provided.{" "}
                </li>
              </ol>
            </div>

            <div>
              <h2 class="text-lg sm:text-xl font-semibold mb-2 dark:text-white">
                ACCOUNT
              </h2>
              <p class="dark:text-gray-400 mb-2">
                For you to use the Platform, you will need to register for a
                group account. During registration, to be a user of the Platform
                (either as Chairperson, Secretary, Treasurer or Member), you
                shall complete a registration form and supply the following
                information: Sacco Name, Registration Number, Sacco Location,
                Member name, Member Position, address, phone number, email
                address and any other information as shall be required. You must
                ensure that all information you provide is accurate and up to
                date.
              </p>
              <p class="dark:text-gray-400 mb-2">
                You agree that all activities that occur in your account
                (including without limitation, posting any company or product
                information, clicking to accept any guidelines, rules, terms and
                conditions, subscribing to or making any payment for any
                services, or sending emails using the Platform’s messaging
                system) will be deemed to have been approved and authorized by
                you as a user, and therefore you also represent and warrant to
                have the full authority to undertake the posting and any
                associated disclosures.
              </p>
              <p class="dark:text-gray-400 mb-2">
                You are solely and purely accountable for the activity or
                activities that occur on your account and for ensuring that your
                PIN(s) and password(s) are secure and confidential.
              </p>
              <p class="dark:text-gray-400 mb-2">
                We reserve the right, at our sole and final discretion, to
                deactivate, freeze, suspend or stop your account upon any
                factual or alleged breach of these Terms.
              </p>
              <p class="dark:text-gray-400 mb-2">
                You shall alert us at once of any change in your accessibility
                of the Platform if you suspect a breach of security or
                unauthorized use of your account.
              </p>
              <p class="dark:text-gray-400 mb-2">
                You represent, warrant, and confirm that:
                <ul class="list-disc">
                  <li>
                    You have full power and authority to accept these Terms, to
                    grant any license and authorization and to perform any of
                    your obligations hereunder.{" "}
                  </li>
                  <li>
                    You will always use the Platform for intended business and
                    lawful purposes only.
                  </li>
                </ul>
              </p>
            </div>
            <div>
              <h2 class="text-lg sm:text-xl font-semibold mb-2 dark:text-white">
                SAVINGS AND LENDING
              </h2>
              <p class="dark:text-gray-400">
                The Platform allows you to save money and apply for loans. All
                savings and lending transactions are subject to our approval and
                the terms and conditions of our savings and lending products.
              </p>
            </div>
            <div>
              <h2 class="text-lg sm:text-xl font-semibold mb-2 dark:text-white">
                FEES & PAYMENTS
              </h2>
              <p class="dark:text-gray-400">
                Fees We may charge fees for the use of the Platform and our
                services. All fees will be disclosed to you before you incur
                them.
              </p>
              <p class="dark:text-gray-400">
                Free trials may be provided by us from time to time with no
                obligation to you, other than following these Terms.
              </p>
            </div>
            <div>
              <h2 class="text-lg sm:text-xl font-semibold mb-2 dark:text-white">
                PROHIBITED ACTIVITIES
              </h2>
              <p class="dark:text-gray-400 mb-2">
                The users of the Platform are prohibited from engaging in the
                following activities:
              </p>
              <ol className="list-decimal">
                <li className="mb-6">
                  Infringing these Terms or allowing, encouraging, or aiding
                  others to infringe these terms.{" "}
                </li>
                <li className="mb-6">
                  Plagiarizing and/or infringing on the intellectual property
                  rights or privacy rights of others.{" "}
                </li>
                <li className="mb-6">Data mining this Platform. </li>
                <li className="mb-6">
                  Tempering with the Platform or any of the Services supplied
                  therein.{" "}
                </li>

                <li className="mb-6">
                  Making, Distributing, selling, or trading in any goods,
                  services, materials, content and/or items that:{" "}
                  <ul class="list-disc">
                    <li>
                      Infringe or endanger the intellectual property rights of
                      others.
                    </li>
                    <li>
                      Enable, promote, or cause discrimination, racism, harm,
                      libel, hatred, or violence against any individual or
                      group.
                    </li>
                    <li>Endanger children.</li>
                    <li>Allow illegal or fraudulent activities to occur.</li>
                    <li>
                      Constitute a criminal or capital offense or infringe any
                      applicable law.
                    </li>
                    <li>
                      Obscene, indecent, pornographic, lewd, suggestive, or
                      sexually explicit, depict violence in an explicit,
                      graphic, or gratuitous manner, cause annoyance,
                      inconvenience, or needless anxiety to any person.
                    </li>
                    <li>
                      Illegal, restricted, or prohibited goods and services,
                      prohibited for commerce or transportation by customs,
                      couriers, insurers or any authority.
                    </li>
                  </ul>
                </li>
                <li className="mb-6">
                  Using the Platform account to engage in activities that are
                  like Maano Digi Save’s business.{" "}
                </li>
                <li className="mb-6">
                  Creating a link from the Platform to another website or
                  document without the Platform’s prior written consent.{" "}
                </li>
                <li className="mb-6">
                  Obscuring or editing any intellectual property appearing on
                  the Platform.{" "}
                </li>
                <li className="mb-6">
                  Creating copies or getting works of the Platform or any part
                  thereof.{" "}
                </li>
                <li className="mb-6">
                  Reverse engineering, decompiling, or extracting the Platform’s
                  source code.{" "}
                </li>
                <li className="mb-6">
                  Remitting, making, or causing to deliver unsolicited
                  advertising, email spam or other chain letters.{" "}
                </li>
                <li className="mb-6">
                  Receiving, collecting, transferring, or issuing any personally
                  identifiable information of any person without the written
                  consent of the owner.{" "}
                </li>
                <li className="mb-6">
                  Misrepresenting any affiliation with any legal entity or third
                  Party.{" "}
                </li>
              </ol>
              <p class="dark:text-gray-400 mb-2">
                Any violation of the terms of this Agreement may result to the
                following:
                <ul class="list-disc">
                  <li>
                    Immediate termination of your access to the Platform and use
                    of our Services, without refund, reimbursement, or any other
                    credit on our part.{" "}
                  </li>
                  <li>
                    Suspension of access to the Platform without prior notice or
                    liability of the Platform.{" "}
                  </li>
                </ul>
              </p>
              <p class="dark:text-gray-400 mb-2">
                You agree that you have all rights, title, and interest to all
                the content you shall post, upload, or circulate via the
                Platform. You shall provide the Platform with all necessary
                information, materials, and approval, and give all reasonable
                aid and cooperation necessary for our Services.
              </p>
              <p>
                <strong>BREACH BY USER</strong>
              </p>
              <p>
                You must not use the Platform in any way that causes or may
                cause damage to the Platform or impairment of its availability
                or accessibility. You must not use the Platform in any way that
                is unlawful, illegal, fraudulent, or harmful.
              </p>
              <p>The following amount to breach of the terms by the user:</p>
              <p>
                a) Suspicion&nbsp;that any information provided by the user
                is&nbsp;outdated, incomplete, false, inaccurate, or misleading.
              </p>
              <p>
                b) When the&nbsp;user&rsquo;s actions may cause financial loss
                or legal liability to Maano Digi Save, other users and related
                persons.
              </p>
              <p>
                The Platform shall impose penalties where the user breaches the
                terms of this Agreement. The penalties may include but are not
                limited to the following: restriction, suspension, and
                termination.
              </p>
              <p>
                <strong>SPAM</strong>
              </p>
              <p>a) We shall screen all material and data on the Platform.</p>
              <p>
                b) Any content found to contain spam shall be deleted by the
                Platform.
              </p>
              <p>
                c) Massive delivery of unwelcome bulk communication via the
                Platform to other users of the Platform is prohibited.
              </p>
              <p>
                d) Any commercial electronic communication (&ldquo;CEM&rdquo;)
                that you&nbsp;may&nbsp;receive&nbsp;from us, or our
                partners,&nbsp;licensors, suppliers, and affiliates will require
                your prior consent. &nbsp;
              </p>
              <p>
                e) If you want to stop&nbsp;receiving&nbsp;our CEMs,&nbsp;there
                will be linked to&nbsp;unsubscribe.&nbsp;The Policy provides
                more information.
              </p>
              <p>
                <strong>License</strong>
              </p>
              <p>
                a) When you&nbsp;post or display any&nbsp;information&nbsp;on
                the Platform or&nbsp;provide&nbsp;any user-generated content
                to&nbsp;the Platform&nbsp;(hereinafter&nbsp;referred to as
                &ldquo;User Content&rdquo;), you grant&nbsp;us&nbsp;an
                irrevocable, perpetual, worldwide, royalty-free, and
                sub-licensable license to display, transmit, distribute,
                reproduce, publish, duplicate, adapt, modify, translate, create
                derivative works, and otherwise use any or all of the User
                Content in any form, media, or technology now known or not
                currently known in any manner and for any purpose which may be
                beneficial to the operation of the Platform, the provision of
                any Services and/or the business of the User.
              </p>
              <p>
                b) You represent, confirm,
                and&nbsp;justify&nbsp;to&nbsp;us&nbsp;that you have all
                the&nbsp;necessary&nbsp;rights, powers,&nbsp;and authority to
                grant the above license.
              </p>
              <p>
                c) For&nbsp;you to use and access&nbsp;the Services on
                the&nbsp;Platform,&nbsp;we&nbsp;grant you a limited license,
                that is royalty-free, non-sub licensable, non-exclusive,
                revocable, and non-transferable, and&nbsp;it applies worldwide.
              </p>
              <p>
                d) You grant&nbsp;the Platform&nbsp;and its affiliates an
                unlimited, non-exclusive, assignable, transferable,
                sub-licensable, irrevocable, royalty free, perpetual and for all
                the countries and territories in the world, right and license to
                use any feedback, suggestion, enhancement, request,
                recommendation, correction, or comment provided by you
                to&nbsp;Maano Digi Save&nbsp;regarding the Platform, and to
                exploit and otherwise incorporate it into the Platform.
              </p>
              <p>
                <strong>OWNERSHIP</strong>
              </p>
              <p>
                a) All the intellectual property&nbsp;displayed on the
                Platform&nbsp;is&nbsp;the property&nbsp;of&nbsp;Maano Digi
                Save&nbsp;and is licensed&nbsp;to&nbsp;the Platform&nbsp;or its
                licensors or affiliates, whether acknowledged or not.
              </p>
              <p>
                b) The&nbsp;respective title holders&nbsp;of the intellectual
                property on the Platform&nbsp;may or may not
                be&nbsp;associated&nbsp;with&nbsp;the Platform&nbsp;or our
                affiliates, partners, and advertisers.
              </p>
              <p>
                c) No section&nbsp;of this agreement&nbsp;shall be
                interpreted&nbsp;to mean that you have been granted&nbsp;any
                interest in the Platform.&nbsp;&nbsp;
              </p>
              <p>
                d) All content and materials included as part
                of&nbsp;our&nbsp;Services, such as images, photographs,
                graphics, texts, forms, lists, charts, guidelines, data, logos,
                code, icons, videos, audio, and other content are the property
                of, are licensed to or are otherwise duly available to Msimbo
                Integrated Technologies Limited, its affiliates, its licensors
                or to the&nbsp;relating&nbsp;third-Party copyrights holder.
              </p>
              <p>
                <strong>ENDORSEMENT</strong>
              </p>
              <p>
                a) Some of the content displayed in the Platform is undertaken
                by external third Parties, and does not reflect&nbsp;the
                Platform&rsquo;s&nbsp;opinions, nor does the Platform,&nbsp;its
                affiliates, subsidiaries, officers, employees, and agents
                guarantee its actual accuracy or make any endorsement thereof.
              </p>
              <p>
                b) The content and media displayed on or&nbsp;via&nbsp;the
                Platform are not necessarily available and any results therefrom
                which we cannot control are out of our scope. &nbsp;
              </p>
              <p>
                c) Some or all the images shown in the Platform are licensed
                and/or purchased&nbsp;standard&nbsp;photos and are only shown
                for illustration purposes. &nbsp;
              </p>
              <p>
                d) The Platform&nbsp;shall not be required to&nbsp;keep track
                of&nbsp;nor exercise any editorial control whatsoever over the
                content of any message or material or information created,
                obtained or accessible through the Services or Platform.
                &nbsp;Each registered user is&nbsp;exclusively&nbsp;responsible
                for the contents of their communications and&nbsp;shall&nbsp;be
                held legally liable or accountable for the content of their
                comments or other material or information.
              </p>
              <p>
                <strong>USER PRIVACY</strong>
              </p>
              <p>a) Any disclosure of data to us follows our Privacy Policy.</p>
              <p>
                b) For more information, please read our&nbsp;
                <a target="_blank" href="">
                  Privacy Policy
                </a>
                .&nbsp;
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>THE TERM OF THE AGREEMENT</strong>
              </p>
              <p>
                a) The contract shall commence when any of the following
                activities happen:
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                i.&nbsp;&nbsp;&nbsp;&nbsp; The&nbsp;first access to the
                Platform.
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ii.&nbsp;&nbsp;&nbsp;&nbsp;
                The&nbsp;first access or execution of our Services.
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp; iii.&nbsp;&nbsp;&nbsp;&nbsp; When the
                Platform&nbsp;begins providing its Services to you.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>TERMINATION&nbsp;OF THE AGREEMENT</strong>
              </p>
              <p>
                a)&nbsp; The term&nbsp;of the contract&nbsp;will automatically
                end&nbsp;when any of the following happens:
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                i.&nbsp;&nbsp;&nbsp;&nbsp; When the account
                is&nbsp;deactivated,&nbsp;suspended,&nbsp;frozen&nbsp;or&nbsp;deleted.
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                ii.&nbsp;&nbsp;&nbsp;&nbsp; Access&nbsp;termination or access
                revocation for our Services or the Platform.
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; iii.&nbsp;&nbsp;&nbsp;&nbsp; The
                Platform&rsquo;s&nbsp;termination of these Terms or its
                Services, as its sole and final discretion.
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                iv.&nbsp;&nbsp;&nbsp;&nbsp; The&nbsp;termination date indicated
                by&nbsp;the Platform&nbsp;to you from time to&nbsp;time.
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                v.&nbsp;&nbsp;&nbsp;&nbsp; When the Platform indicates or
                decides that the Platform will&nbsp;no
                longer&nbsp;be&nbsp;available for use.
              </p>
              <p>
                b)&nbsp; The Platform will provide&nbsp;prior notice of the
                suspension or termination of the Services.
              </p>
              <p>
                c)&nbsp; The User shall be&nbsp;liable for all
                transactions&nbsp;that were started&nbsp;prior
                to&nbsp;the&nbsp;termination&nbsp;and to deliver all pending
                obligations prior to the termination.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>AMENDMENTS</strong>
              </p>
              <p>
                a) The Platform reserves all the rights and at its sole
                discretion to make any necessary amendments on the Platform,
                terms, Privacy Policy, and all related areas at any time.
              </p>
              <p>
                b) The amendments to be made may include the Platform&rsquo;s
                functionalities and any other applicable fee(s) as the case
                maybe.
              </p>
              <p>
                c) The changes made to the terms and Privacy Policy shall
                be&nbsp;displayed in the Platform&nbsp;and the users shall be
                notified&nbsp;through the Platform or&nbsp;via&nbsp;email.
                &nbsp;
              </p>
              <p>
                d) The dates indicated below the terms and privacy
                condition&nbsp;show the date&nbsp;when&nbsp;effective changes
                were last undertaken by us. &nbsp;
              </p>
              <p>
                Usage of the Platform&nbsp;after the effective date of any
                update either by an account registration or simple
                use&nbsp;denotes acceptance of the terms as they are.
              </p>
              <p>
                <strong>RISK ACKNOWLEDGMENT BY USERS</strong>
              </p>
              <p>
                a) The users shall bear all the risk and liabilities that may
                all arise on all information of all kinds obtained from the
                Platform. The users shall indemnify the Platform, its affiliates
                and employees from all liabilities whatsoever arising out of all
                information obtained via the Platform.
              </p>
              <p>
                b) Such risks include but are not limited
                to:&nbsp;misrepresentation of products and services, fraudulent
                schemes, unsatisfactory quality, failure to meet specifications,
                defective or dangerous products, unlawful products, delay or
                default in delivery or payment, cost miscalculations, breach of
                warranty, breach of contract and transportation accidents.
              </p>
              <p>
                c) It also&nbsp;includes&nbsp;the risks that the importation,
                export, distribution, offer, display, purchase, sale and/or use
                of products or services offered or displayed on the Platform may
                violate or may be asserted to violate third Party rights, and
                the risk that&nbsp;the&nbsp;user may incur costs of defence or
                other costs in connection with third Parties&rsquo; assertion of
                third Party rights, or in connection with any claims by any
                Party that they are entitled to defence or indemnification in
                relation to assertions of rights, demands or claims by third
                Party rights claimants.
              </p>
              <p>
                d) The&nbsp;risks also include the risks that consumers, other
                purchasers, end-users of products or others claiming to have
                suffered injuries or harms relating to products originally
                obtained by users of the Platform because of purchase and sale
                transactions in connection with using the Platform may suffer
                harm and/or assert claims arising from their use of such
                products. Each user agrees that&nbsp;the Platform&nbsp;shall not
                be liable or responsible for any damages, claims, liabilities,
                costs, harm, inconveniences, business disruptions or
                expenditures of any kind that may arise because of or in
                connection with any order risks.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>NO&nbsp;WARRANTY</strong>
              </p>
              <p>
                a) You acknowledge that you shall use&nbsp;our Platform at your
                own risk, and therefore you&nbsp;agree&nbsp;that we supply our
                Platform and Services &ldquo;as is&rdquo;, &ldquo;with all
                faults&rdquo;, and &ldquo;as available&rdquo;, including all
                content, guides, checklists, reference guides, sample filing
                forms, software, materials, services, functions and/or
                information made available thereby. &nbsp;It shall be your own
                responsibility to ensure that any products, Services, or
                information available through this Platform meet your specific
                requirements.
              </p>
              <p>
                b) We, our&nbsp;affiliates, subsidiaries, officers, employees
                and agents&nbsp;do not&nbsp;warrant that the Platform will be
                error-free, uninterrupted, secure, or produce any particular
                results; or that any listing, purchase, order, amount,
                information, guide, sheet, checklist and/or content will be
                current, measured useful and/or valid, or that it will produce
                any particular results or that the information obtained
                therefrom will be reliable or accurate. &nbsp;No advice or
                information given by&nbsp;the Platform&nbsp;or its employees,
                affiliates, contractors and/or agents shall create a guarantee.
                No warranty or representation is made about such services or
                products of third Parties contacted through the Platform.
                &nbsp;In no event shall&nbsp;the Platform&nbsp;or our affiliates
                be held liable for any such services or products.
              </p>
              <p>
                c) We, our&nbsp;affiliates, licensors, owners, subsidiaries,
                brands, or advertisers are&nbsp;not&nbsp;professional
                advisors&nbsp;in any industry. &nbsp;The results described in
                the Platform are not distinctive and will vary based on a
                variety of factors outside&nbsp;our control. &nbsp;Your use of
                any information and/or materials on this Platform is entirely at
                your own risk, for which we shall not be held liable.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>DISCLAIMER OF DAMAGES</strong>
              </p>
              <p>
                a) We shall not&nbsp;be liable to you or to any third Party for
                any direct, indirect, incidental, consequential, special,
                exemplary, or punitive damages, including but not limited to:
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                i.&nbsp;&nbsp;&nbsp;&nbsp; Damages&nbsp;for business
                interruption, loss of profits, loss of data, computer or
                software failure or inaccessibility or any other type of
                personal damages or losses arising out of or related to your use
                of or inability to use the Platform, including negligence.
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                ii.&nbsp;&nbsp;&nbsp;&nbsp; Any defect or nonconformance in
                goods, samples, data, information, or services obtained from a
                User or any other third Party through the Platform or the
                ability of the Seller to complete a sale or the ability of the
                Buyer to complete a purchase.
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; iii.&nbsp;&nbsp;&nbsp;&nbsp;
                Infringement of third-Party intellectual property rights.
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                iv.&nbsp;&nbsp;&nbsp;&nbsp; Claims or demands that any
                collection, importation, export, distribution, offer, display,
                purchase, sale and/or use of products or Services offered or
                displayed on the Platform may violate or may be asserted to
                violate third Party rights; and/or
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                v.&nbsp;&nbsp;&nbsp;&nbsp; Claims by any Party that they are
                entitled to defence or indemnification in relation to assertions
                of rights, demands, or claims by third Party rights claimants.
              </p>
              <p>
                b) The limitation of damage liability&nbsp;mentioned above,
                shall be in force regardless of&nbsp;how it is&nbsp;caused
                or&nbsp;how it was awarded, regardless of the theory of
                liability applied (including contract, warranty or tort),
                whether active, passive or imputed, including negligence, strict
                liability, product liability or other legal theory, regardless
                of the product or service offered by action or inaction by a
                merchant; and even if you have been advised of such possibility.
              </p>
              <p>
                c) We disclaim&nbsp;all representations and warranties of any
                kind with respect to the Platform&nbsp;whether express or
                implied.
              </p>
              <p>&nbsp;</p>
              <p>
                <strong>LIMITATION OF LIABILITY</strong>
              </p>
              <p>
                a)&nbsp;&nbsp;&nbsp;There are risks and benefits of the services
                provided via the Platform.&nbsp;In&nbsp;appreciation&nbsp;of the
                risks,&nbsp;to both&nbsp;us&nbsp;and the&nbsp;user, the risks
                have been allocated such that&nbsp;the user&nbsp;hereby
                acknowledges and agrees, to the fullest extent permitted by law,
                to limit the maximum cumulative liability of&nbsp;the
                Platform&nbsp;to you for any and all claims, losses, costs,
                damages of any nature, arising out of or related to your use of
                the Platform and any Services thereon or whatsoever claims
                expenses from any cause or causes (including reasonable
                attorneys&rsquo; fees and costs and expert witness fees and
                costs), to the amount that is&nbsp;greater among the following:
              </p>
              <p>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                i.&nbsp;&nbsp;&nbsp;&nbsp; Any&nbsp;amount&nbsp;you have paid us
                for Services provided by the Platform&nbsp;during the twelve
                (12) months prior to the action giving rise to such liability.
              </p>
              <p>
                b)&nbsp;&nbsp;&nbsp;The above mentioned notwithstanding, the
                user shall still&nbsp;prove&nbsp;the&nbsp;actual damages.
                &nbsp;All claims arising from the use of the Platform or
                Services must be filed within one (1) year from the date the
                cause of action arose.
              </p>
              <p>
                c)&nbsp;&nbsp;&nbsp;Some&nbsp;jurisdictions do not allow the
                exclusion or limitation of implied warranties or
                damages.&nbsp;Therefore, if you fall under the rule of such
                jurisdictions, part or all of the disclaimers or liability
                limitations mentioned herein may not apply to you, and you might
                have supplementary rights not limited by law.
              </p>
              <p>
                <strong>INDEMNIFICATION</strong>
              </p>
              <p>
                a) You&nbsp;shall&nbsp;indemnify&nbsp;the Platform&nbsp;and its
                independent contractors, affiliates, subsidiaries, officers,
                employees and agents, and their respective employees, agents and
                representatives, harmless from and against any&nbsp;claim or
                demand (including reasonable legal fees) made or incurred by any
                third Party due to or arising out of your breach of this user
                agreement, your improper use of the Platform, your violation of
                any law or the rights of a third Party and/or the actions or
                inactions of any third Party to whom you grant permission to use
                your account or access our Platform, software, systems
                (including any networks and servers used to provide any of our
                services) operated by us or on our behalf, or any of the
                services on your behalf.
              </p>
              <p>&nbsp;</p>
              <p>
                b) You&nbsp;further&nbsp;agree to&nbsp;sort&nbsp;all costs and
                expenses (including reasonable attorneys&#39; fees)
                that&nbsp;the Platform&nbsp;may incur to collect any amounts you
                owe under these Terms and/or&nbsp;any other&nbsp;Additional
                Agreement&nbsp;(where applicable).
              </p>
              <p>
                <strong>GENERAL&nbsp;PROVISIONS</strong>
              </p>
              <p>1. Advertisements and Promotions</p>
              <p>
                We may&nbsp;occasionally put advertisements&nbsp;and
                promotions&nbsp;in the Platform&nbsp;from third Party sources.
                &nbsp;Hence, your participation in promotions of third Parties
                other than&nbsp;the Platform, and any terms, conditions,
                warranties, or representations associated with such
                undertakings, are&nbsp;purely&nbsp;between you and such third
                Party. &nbsp;We shall&nbsp;not&nbsp;be held&nbsp;accountable or
                liable for any loss or damage of any&nbsp;kind&nbsp;incurred
                because of any such dealings or because of the presence of
                third-Party advertisers&nbsp;in the Platform.
              </p>
              <p>&nbsp;</p>
              <p>2. Assignment &nbsp;</p>
              <p>
                These terms shall not be assignable or transferrable by the User
                in whichever way&nbsp;without our prior written consent.
                &nbsp;We&nbsp;reserve the right to assign&nbsp;any rights or
                obligations hereunder to any current or future affiliated
                company and to any successor in interest. &nbsp;Any rights not
                expressly granted herein are hereby reserved. &nbsp;These terms
                will&nbsp;vest&nbsp;to the benefit of any successors of the
                Parties. &nbsp;We reserve the right, at any time, to transfer
                some or all&nbsp;our&nbsp;assets in connection with a merger,
                acquisition, reorganization, or sale of assets or in the event
                of bankruptcy.
              </p>
              <p>&nbsp;</p>
              <p>3. Content Moderation &nbsp;</p>
              <p>
                We&nbsp;reserve the right, at&nbsp;our&nbsp;sole
                and&nbsp;ultimate&nbsp;discretion,
                to&nbsp;analyse&nbsp;any&nbsp;and scrutinize&nbsp;all content
                delivered into the Platform and use any monitoring technology to
                flag and remove any user generated content
                or&nbsp;inappropriate&nbsp;content.
              </p>
              <p>&nbsp;</p>
              <p>4. Force Majeure &nbsp;</p>
              <p>
                We shall&nbsp; not&nbsp;be&nbsp;liable for any failure of
                performance of our&nbsp;obligations
                as&nbsp;provided&nbsp;herein, where such failure arises from any
                cause beyond&nbsp;our&nbsp;reasonable control, including but
                not&nbsp;limited&nbsp;to, electronic, power, mechanic or
                Internet failure, from acts of nature, forces or causes beyond
                our control, including without limitation, Internet failures,
                computer, telecommunications or any other equipment failures,
                electrical power failures, strikes, labour disputes, riots,
                insurrections, civil disturbances, shortages of labour or
                materials, fires, flood, storms, explosions, acts of God, war,
                governmental actions, orders of domestic or foreign courts or
                tribunals or non-performance of third Parties.
              </p>
              <p>&nbsp;</p>
              <p>5. Headings &nbsp;</p>
              <p>
                The titles of paragraphs in these Terms are shown only for ease
                of reference and will not affect any interpretation therefrom.
              </p>
              <p>&nbsp;</p>
              <p>6. No Waiver &nbsp;</p>
              <p>
                Failure by&nbsp;the Platform&nbsp;to enforce any rights
                hereunder shall not be construed as a waiver of any rights with
                respect to the subject matter hereof.
              </p>
              <p>&nbsp;</p>
              <p>7. No Relationship&nbsp;</p>
              <p>
                You and&nbsp;Us&nbsp;are independent contractors, and no agency,
                partnership, joint venture, employee-employer, or
                franchiser-franchisee relationship is intended or created by
                these Terms.
              </p>
              <p>&nbsp;</p>
              <p>8. Notices</p>
              <p>
                All legal notices or demands&nbsp;to be served upon the
                Platform&nbsp;shall be made in writing and sent to&nbsp;Msimbo
                IT&nbsp;by personal service, courier, certified mail, or
                facsimile, and shall be delivered to any address the Parties may
                provide. &nbsp;As far as e-mail is concerned,&nbsp;the date of
                receipt&nbsp;shall&nbsp;be within{" "}
                <strong>twenty-four (24) hours</strong> from the time the e-mail
                was send. All correspondences&nbsp;that&nbsp;we&nbsp;send to you
                electronically satisfy the legal requirement that such
                communication should be in writing.
              </p>
              <p>&nbsp;</p>
              <p>9. Severability &nbsp;</p>
              <p>
                <a href="https://www.lawinsider.com/clause/severability-clause">
                  In the
                </a>
                &nbsp;event any provision or part of this Agreement is found to
                be invalid or unenforceable, only that particular provision or
                part so found, and not the entire Agreement, will be
                inoperative. Failure&nbsp;of either Party to exercise in
                any&nbsp;way&nbsp;any right provided for&nbsp;in this
                Agreement&nbsp;will not be deemed a waiver of any further rights
                hereunder.
              </p>
              <p>&nbsp;</p>
              <p>
                10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Equitable
                remedies &nbsp;
              </p>
              <p>
                If these&nbsp;Terms are not specifically enforced,&nbsp;the
                Platform&nbsp;will be irreparably damaged, and therefore you
                agree that&nbsp;the Platform&nbsp;shall be entitled, without
                bond, other security, or proof of damages, to appropriate
                equitable remedies with respect to your breach of any of these
                Terms, in addition to any other available remedies.
              </p>
              <p>
                <strong>APPLICABLE LAW</strong>
              </p>
              <p>
                Any dispute that might arise between the Parties herein because
                of using the Platform&nbsp;shall be subject to the laws of the
                Republic of Kenya, without regard to conflict of law principles.
              </p>
              <p>
                <strong>DISPUTE RESOLUTION</strong>
              </p>
              <p>
                a) Any dispute, controversy or claim arising out of or in
                connection to this contract, or breach, termination or
                invalidity thereof shall be settled by arbitration in accordance
                with the Nairobi Centre for International Arbitration (NCIA)
                Rules.
              </p>
              <p>
                b) The arbitration shall be conducted in the English language in
                the City of Nairobi, Kenya, in accordance with the provisions
                set forth in the rules of the NCIA. &nbsp;The arbitral tribunal
                shall comprise of one (1) arbitrator, appointed by the NCIA, who
                shall preside over the arbitral tribunal. The arbitrator&rsquo;s
                decision will follow the terms of this Agreement.
              </p>
              <p>
                c) The arbitrator will have the authority to award temporary,
                interim or permanent injunctive relief or relief providing for
                specific performance of this Agreement, but only to the extent
                necessary to provide relief warranted by the individual claim
                before the arbitrator. &nbsp;The award rendered by the
                arbitrator may be confirmed and enforced in any court having
                jurisdiction thereof.
              </p>
              <p>
                d) The arbitration must be concluded, and an award issued, no
                later than one hundred and twenty (120 days) following the
                filing of the demand for arbitration, unless all Parties to the
                arbitration proceeding agree in writing to an extension.
              </p>
              <p>
                e) If you bring a dispute in a manner other than in accordance
                with this section, you agree that we may move to have it
                dismissed, and that you will be responsible for our reasonable
                Advocate&rsquo;s fees, court costs, and disbursements. &nbsp;You
                agree that the unsuccessful Party in any dispute arising from or
                relating to these Terms will be responsible for the
                reimbursement of the successful Party&rsquo;s reasonable
                Advocate&rsquo;s fees, court costs&nbsp;(where applicable), and
                disbursements.
              </p>
              <p>
                <strong>CONTACT</strong>
              </p>
              <p>
                For any inquires or complaints regarding the Service or
                Platform, please contact us by email to&nbsp;
                <a
                  className="text-sky-500"
                  href="mailto:Maano Digi Save@msimboit.net"
                >
                  Maano Digi Save@msimboit.net
                </a>
                <strong> </strong>and by telephone to&nbsp;<strong>+254</strong>
                <strong> </strong>
                <strong>716 003 792</strong>
                <strong>.</strong>
              </p>
            </div>
          </div>
        </div>
      </main>

      <section className="py-10 bg-gray-900 sm:pt-16 lg:pt-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="flex flex-wrap items-center justify-between">
            <img
              className="h-8 auto md:order-1"
              src={require("../img/msimbo.PNG")}
              alt=""
            />
            <ul className="flex items-center space-x-3 md:order-3">
              <Link to="/faq">
                <li className="text-white transition-all duration-200 bg-transparent    hover:text-sky-500 ">
                  FAQs
                </li>
              </Link>
              <Link to="/terms">
                <li className="text-white transition-all duration-200 bg-transparent    hover:text-sky-500 ">
                  Terms and Conditions
                </li>
              </Link>
              <Link to="/privacy">
                <li className="text-white transition-all duration-200 bg-transparent    hover:text-sky-500 ">
                  Privacy Policy
                </li>
              </Link>
            </ul>

            <p className="w-full mt-8 text-sm text-center text-gray-100 md:mt-0 md:w-auto md:order-2">
              © Copyright 2023 Msimbo DevOps. All Rights Reserved.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Terms;
