import Axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { authContext } from "../Context/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri';
function Try() {
  const [mobile_no, setMobile] = useState("");
  const [lpassword, setLPassword] = useState("");
  const [allErr, setallErr] = useState(false);
  const [userErr, setuserErr] = useState(false);
  const [passwordErr, setpasswordErr] = useState(false);
  const { logged, setLogged } = useContext(authContext);
  const { setUserName } = useContext(authContext);
  const { setUserMobile } = useContext(authContext);
  const { setUserID } = useContext(authContext);
  const { setUserRole } = useContext(authContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const MySwal = withReactContent(Swal);

  // const [isTermsAccepted, setTermsAccepted] = useState(false);

  // const handleCheckboxChange = () => {
  //   setTermsAccepted(!isTermsAccepted);
  // };
 
     // password toggle
     const [showPassword, setShowPassword] = useState();

     const togglePasswordVisibility = () => {
      // console.log("Toggling password visibility");
       setShowPassword(!showPassword);
      //  console.log("New showPassword state:", !showPassword);
     };

  const userLogin = (e) => {

    e.preventDefault();

    if (mobile_no && lpassword) {
      // if (isTermsAccepted) {
        setLoading(true);

        Axios.post(
          "https://us-central1-chillimbasaffe.cloudfunctions.net/basicauth",
          { message: { mobile_no: mobile_no, password: lpassword } },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
          .then((res) => {
            console.log(res.data);
            localStorage.setItem("userToken", res.data.access_token);
            // const token = localStorage.getItem("userToken");

            Axios.post(
              "https://us-central1-chillimbasaffe.cloudfunctions.net/Login",
              {
                message: {
                  access_token: res.data.access_token,
                  mobile_no: mobile_no,
                  lpassword: lpassword,
                },
              },
              {
                headers: { "Content-Type": "application/json" },
              }
            )
              .then((res) => {
                 console.log("Posting Data From::", res.data);

                if (res.data.response === "Successfully") {
                  setLoading(false);
                  MySwal.fire({
                    position: "center",
                    icon: "success",
                    title: "Login Successfully",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  let userMobile = {
                    message: {
                      access_token: localStorage.getItem("userToken"),
                      mobile_no: mobile_no,
                    },
                  };

                  Axios.post(
                    "https://us-central1-chillimbasaffe.cloudfunctions.net/getalluserinfo",
                    userMobile,
                    {
                      headers: { "Content-Type": "application/json" },
                    }
                  )
                    .then((res) => {
                      localStorage.setItem("userMobile", mobile_no);

                      // setLogged(true);
                      // setUserName(res.data.data.name);
                      // setUserMobile(res.data.data.mobile_no);
                      // setUserID(res.data.data.id_no);
                      // setUserRole(res.data.data.role);
                      // console.log("GEtting ROLE DATA From::", res.data.data);

                      if (res.data.data[mobile_no].role === "admin") {
                        localStorage.setItem(
                          "userRole",
                          res.data.data[mobile_no].role
                        );
                        setLogged(true);
                        setUserName(res.data.data[mobile_no].name);
                        setUserMobile(res.data.data[mobile_no].mobile_no);
                        setUserID(res.data.data[mobile_no].id_no);
                        setUserRole(res.data.data[mobile_no].role);
                        navigate("/dashboard");
                      } else if (
                        res.data.data[mobile_no].role === "affiliate"
                      ) {
                        localStorage.setItem(
                          "userRole",
                          res.data.data[mobile_no].role
                        );
                        setLogged(true);
                        setUserName(res.data.data[mobile_no].name);
                        setUserMobile(res.data.data[mobile_no].mobile_no);
                        setUserID(res.data.data[mobile_no].id_no);
                        setUserRole(res.data.data[mobile_no].role);
                        navigate("/affDashboard");
                      } else {
                        navigate("/userSelectSHG");
                      }
                    })
                    .catch((err) => console.log(err));
                } else if (res.data.code === 505) {
                  setpasswordErr(true);
                  setLoading(false);
                } else if (res.data.code === 404) {
                  setuserErr(true);
                setLoading(false);
                }
              })
              .catch((err) => {
                setLoading(false);
                MySwal.fire({
                  position: "center",
                  icon: "error",
                  title: "Login Error",
                  text: "Check Your Credentials!",
                });
                setuserErr(true);
                setpasswordErr(true);
                navigate("/signIn");
                setLoading(false);
              });
          })
          .catch((err) => {
            setLoading(false);
            MySwal.fire({
              position: "center",
              icon: "error",
              title: "Login Error",
              text: "Check Your Credentials!",
            });
            setuserErr(true);
            setpasswordErr(true);
            setLoading(false);
          });
      // } else {
      //   MySwal.fire({
      //     position: "center",
      //     icon: "error",
      //     title: "Agree To Terms And Conditions",
      //   });
      // }
    } else {
      setallErr(true);
    }
  };



  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      checkLogin();

      return () => {
        effectRan.current = true;
      };
    }
  }, []);

  const checkLogin = () => {
    if (localStorage.getItem("userMobile")) {
      setLogged(true);
      const useRole = localStorage.getItem("userRole");

      if (useRole === "admin") {
        navigate("/dashboard");
      } else if (useRole === "chairman") {
        navigate("/chairDashboard");
      } else if (useRole === "secretary") {
        navigate("/secDashboard");
      } else if (useRole === "treasurer") {
        navigate("/tresDashboard");
      } else if (useRole === "affiliate") {
        navigate("/affDashboard");
      } else if (useRole === "user") {
        navigate("/userDashboard");
      }
    }
  };

  return (
    <>
      <div
        className={`${
          !loading && "hidden"
        } w-full h-full fixed  top-0 left-0 bg-white opacity-75 z-50`}
      >
        <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
          <i className="fas fa-circle-notch fa-spin fa-5x"></i>
        </span>
      </div>

      <div className="tp-main-wrapper h-screen hero-bg">
        <div className="container mx-auto my-auto h-full flex items-center justify-center">
          <div className="pt-[80px] pb-[120px]">
            <div className="grid grid-cols-12 shadow-lg bg-white overflow-hidden rounded-md ">
              <div className="col-span-4 lg:col-span-6 relative h-full hidden lg:block">
                <div className="data-bg absolute top-0 left-0 w-full h-full bg-cover bg-no-repeat login-bg-1"></div>
              </div>
              <div className="col-span-12 lg:col-span-6 md:w-[500px] mx-auto my-auto  pt-[50px] py-[60px] px-5 md:px-[60px]">
                <div className="text-center">
                  <h4 className="text-[24px] mb-1">Login Now.</h4>
                </div>
                <div
                  className={`${
                    !allErr && "hidden"
                  } bg-orange-100 my-5    border-t-4 border-orange-500 rounded-b text-orange-900 px-4 py-3 shadow-md`}
                  role="alert"
                >
                  <div className="flex">
                    <div className="py-1">
                      <svg
                        className="fill-current h-6 w-6 text-orange-500 mr-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                      </svg>
                    </div>
                    <div>
                      <p className="font-bold">Error Occured While Login</p>
                      <p className="text-sm">* All Fields are Required</p>
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    !userErr && "hidden"
                  } bg-red-100 my-5    border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md`}
                  role="alert"
                >
                  <div className="flex">
                    <div className="py-1">
                      <svg
                        className="fill-current h-6 w-6 text-red-500 mr-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                      </svg>
                    </div>
                    <div>
                      <p className="font-bold">Error Occured While Login</p>
                      <p className="text-sm">Insert Correct Credentials</p>
                    </div>
                  </div>
                </div>

                <div className="">
                  <form action="#">
                    <div className="mb-5">
                      <p className="mb-0 text-base text-black">
                        Mobile No <span className="text-red">*</span>
                      </p>
                      <input
                        className="input w-full h-[49px] rounded-md border border-gray6 px-6 text-base"
                        type="tel"
                        placeholder="Enter Your Mobile"
                        value={mobile_no}
                        aria-label="mobile_no"
                        onChange={(e) => {
                          setMobile(e.target.value);
                          setuserErr(false);
                          setallErr(false);
                        }}
                      />
                    </div>
                    <div className="mb-5">
                    <p className="mb-0 text-base text-black">
                      Password <span className="text-red">*</span>
                    </p>
                    <div className="relative">
                      <input
                        className={`${
                          passwordErr && "border-red-500 border text-red-900"
                        } input w-full h-[49px] rounded-md border border-gray6 px-6 text-base`}
                        type={showPassword ? "text" : "password"} // Dynamic type based on showPassword state
                        placeholder="Password"
                        value={lpassword}
                        onChange={(e) => {
                          setLPassword(e.target.value);
                          setuserErr(false);
                          setpasswordErr(false);
                          setallErr(false);
                        }}
                      />
                      <span
                        className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <RiEyeCloseLine size={18} /> : <RiEyeLine size={18} />}
                      </span>
                    </div>
                  </div>

                    {/* <div className="flex items-center justify-between">
                      <div className="tp-checkbox flex items-start space-x-2 mb-3">
                        <input
                          id="product-1"
                          type="checkbox"
                          checked={isTermsAccepted}
                          onChange={handleCheckboxChange}
                        />
                        <label for="product-1" className="text-tiny">
                          I agree to the <strong>Terms and Conditions</strong>{" "}
                        </label>
                      </div>
                    </div> */}
                    <button
                      onClick={userLogin}
                      className="tp-btn h-[49px] w-full justify-center bg-green-600 hover:bg-green-700"
                    >
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className="inline-block w-4 h-4 mr-2 bi bi-box-arrow-in-right"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
                        <path d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                      </svg>{" "}
                      Sign In
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Try;
