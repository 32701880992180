import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";

function LinkUserShg() {
  //   const navigate = useNavigate();

  const [shgname, setShgName] = useState("");
  const [allErr, setallErr] = useState(false);
  const [userErr, setuserErr] = useState(false);
  const [shg, setSHG] = useState([]);
  const { mobile_no } = useParams();
  const [role, setRole] = useState("chairman");
  const navigate = useNavigate();
  const token = localStorage.getItem("userToken");

  useEffect(() => {
    Axios.post(
      "https://us-central1-chillimbasaffe.cloudfunctions.net/getallselfhelpgroupaccount",
      { message: { access_token: token } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting DAta From::", res.data.data);
        setSHG(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const linkUser = (e) => {
    e.preventDefault();
    // console.log(mobile_no);

    if (shgname) {
      Axios.post(
        "https://us-central1-chillimbasaffe.cloudfunctions.net/linkmembertoaccount",
        {
          message: {
            access_token: token,
            mobile_no: mobile_no,
            accounts: [shgname],
            role: role,
          },
        }
      )
        .then((res) => {
          // console.log("Posting SHG Link Data From::", res.data.response);
          alert(res.data.response);
          navigate("/affUserLinkShg");
        })
        .catch((err) => console.log(err));
    } else {
      setallErr(true);
    }
  };

  const myshg = Object.values(shg);
  const dataSHG = myshg.map((data, index) => {
    return (
      <option key={index} value={data.accountname}>
        {data.accountname}
      </option>
    );
  });

  return (
    <>
      <main className="pt-2 md:pt-20 -mt-2">
        <div className="mx-auto p-2">
          <div className="flex flex-wrap flex-row">
            <div className="flex-shrink max-w-full md:px-4 w-full">
              <p className="text-xl font-bold mt-3 mb-5 dark:text-gray-200">
                Link User to SHG
              </p>
            </div>
            <div className="flex-shrink mx-auto max-w-full md:px-4 w-full lg:w-2/3 mb-6">
              <div
                className={`${
                  !allErr && "hidden"
                } bg-orange-100 my-5    border-t-4 border-orange-500 rounded-b text-orange-900 px-4 py-3 shadow-md`}
                role="alert"
              >
                <div className="flex">
                  <div className="py-1">
                    <svg
                      className="fill-current h-6 w-6 text-orange-500 mr-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </div>
                  <div>
                    <p className="font-bold">
                      Error Occured While Creating SGH
                    </p>
                    <p className="text-sm">* All Fields are Required</p>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  !userErr && "hidden"
                } bg-yellow-100 my-5    border-t-4 border-yellow-500 rounded-b text-yellow-900 px-4 py-3 shadow-md`}
                role="alert"
              >
                <div className="flex">
                  <div className="py-1">
                    <svg
                      className="fill-current h-6 w-6 text-yellow-500 mr-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                  </div>
                  <div>
                    <p className="font-bold">
                      Error Occured While Creating SGH
                    </p>
                    <p className="text-sm">SGH Exists</p>
                  </div>
                </div>
              </div>
              <div className="p-2 md:p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg mb-6">
                <div className="flex-1 p-2 md:p-6 bg-gray-100 dark:bg-gray-900 bg-opacity-60 dark:bg-opacity-20">
                  <form className="flex flex-wrap flex-row -mx-4">
                    <div className="flex-shrink max-w-full px-4 w-full md:w-1/2 mb-6">
                      <label className="inline-block mb-2 dark:text-gray-200">
                        SGH Name
                      </label>

                      <select
                        name="shg"
                        id="inputshg"
                        className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 dark:text-gray-200 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                        value={shgname}
                        aria-label="role"
                        onChange={(e) => {
                          setShgName(e.target.value);
                          setallErr(false);
                          setuserErr(false);
                        }}
                      >
                        {dataSHG}
                      </select>
                    </div>

                    <div className="mb-6">
                      <label className="inline-block mb-2 text-gray-700 dark:text-gray-200">
                        Select User Role
                      </label>
                      <select
                        name="role"
                        id="inputrole"
                        className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 dark:text-gray-200 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                        value={role}
                        aria-label="role"
                        onChange={(e) => {
                          setRole(e.target.value);
                          setallErr(false);
                        }}
                      >
                        <option value="chairman">Chairman</option>
                        <option value="secretary">Secretary</option>
                        <option value="treasurer">Treasurer</option>
                        <option value="user">Member</option>
                      </select>
                    </div>

                    <div className="flex-shrink max-w-full px-4 w-full">
                      <button
                        type="submit"
                        onClick={linkUser}
                        className=" text-center mb-3 rounded py-2 px-5 inline-block   leading-5 text-gray-100 bg-indigo-500 bg-transparent border border-indigo-500 hover:text-gray-100 hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0"
                      >
                        link User +
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default LinkUserShg;
