import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import _ from "lodash";
import { DownloadTableExcel } from "react-export-table-to-excel";
function SecMembers() {
  const secSHG = localStorage.getItem("userSHG");
  const secMobile = localStorage.getItem("userMobile");
  const [users, setUsers] = useState([]);
  const token = localStorage.getItem("userToken");
  const [loading, setLoading] = useState(false);
  const pageSize = 5;
  const [paginatedData, setPaginatedData] = useState([]);
  const [currentDataPage, setCurrentDataPage] = useState(1);

  const userRef = useRef(null);

  useEffect(() => {
    Axios.post(
      "https://us-central1-chillimbasaffe.cloudfunctions.net/getallmembersinSHG",
      { message: { access_token: token, account_name: secSHG } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting Users Data ::", res.data.data);
        setUsers(res.data.data);
        setPaginatedData(
          _(Object.values(res.data.data)).slice(0).take(pageSize).value()
        );
        setLoading(true);
      })
      .catch((err) => console.log(err));
  }, []);

  const myuser = Object.values(users);
  const dataUsers = paginatedData.map((data, index) => {
    if (data.mobile_no == secMobile) {
      return (
        <tr
          key={index}
          class="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
        >
          <td class="border-grey-light border hover:bg-gray-100 p-3">
            <input
              type="checkbox"
              className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-700"
            />
          </td>
          <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
            <Link to={"/secProfile"}>
              <div className="flex flex-wrap flex-row items-center">
                <img
                  className="h-8 w-8 rounded-full"
                  src={require("../../img/avatar8.png")}
                  alt="profile"
                />
              </div>
            </Link>
          </td>
          <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
            <Link to={"/secProfile"}>{data.name}</Link>
          </td>
          <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
            <Link to={"/secProfile"}>{data.role}</Link>
          </td>

          <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
            <Link to={"/secProfile"}>{data.mobile_no}</Link>
          </td>
          <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
            <Link to={"/secProfile"}>
              <span className="text-sm px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full">
                Active
              </span>
            </Link>
          </td>

          <td class="border-grey-light border hover:bg-gray-100 p-3 truncate">
            <Link to={"/secProfile"} title="link">
              <button
                type="button"
                class="hidden px-8 py-2 font-bold leading-normal text-center text-white align-middle transition-all ease-in border-0 rounded-lg shadow-md cursor-pointer text-xs bg-cyan-500 lg:block tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
              >
                View{" "}
              </button>
            </Link>

            {/* <Link to={`/secUserUpdate/${data.mobile_no}`}>
              <button
                type="button"
                class="hidden my-3 px-8 py-2 font-bold leading-normal text-center text-white align-middle transition-all ease-in border-0 rounded-lg shadow-md cursor-pointer text-xs bg-indigo-500 lg:block tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
              >
                Update{" "}
              </button>
            </Link> */}
          </td>
        </tr>
      );
    }
    return (
      <tr
        key={index}
        className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
      >
        <td className="border-grey-light border hover:bg-gray-100 p-3">
          <input
            type="checkbox"
            className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-700"
          />
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          <Link to={`/secMemberView/${data.mobile_no}`}>
            <div className="flex flex-wrap flex-row items-center">
              <img
                className="h-8 w-8 rounded-full"
                src={require("../../img/avatar8.png")}
                alt="profile"
              />
            </div>
          </Link>
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          <Link to={`/secMemberView/${data.mobile_no}`}>{data.name}</Link>
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          <Link to={`/secMemberView/${data.mobile_no}`}>{data.role}</Link>
        </td>

        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          <Link to={`/secMemberView/${data.mobile_no}`}>{data.mobile_no}</Link>
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          <Link to={`/secMemberView/${data.mobile_no}`}>
            <span className="text-sm px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full">
              Active
            </span>
          </Link>
        </td>

        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          <Link to={`/secMemberView/${data.mobile_no}`} title="link">
            <button
              type="button"
              class="hidden px-8 py-2 font-bold leading-normal text-center text-white align-middle transition-all ease-in border-0 rounded-lg shadow-md cursor-pointer text-xs bg-cyan-500 lg:block tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
            >
              View{" "}
            </button>
          </Link>

          {/* <Link to={`/secUserUpdate/${data.mobile_no}`}>
            <button
              type="button"
              class="hidden my-3 px-8 py-2 font-bold leading-normal text-center text-white align-middle transition-all ease-in border-0 rounded-lg shadow-md cursor-pointer text-xs bg-indigo-500 lg:block tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
            >
              Update{" "}
            </button>
          </Link> */}
        </td>
      </tr>
    );
  });

  const pageDataCount = users ? Math.ceil(myuser.length / pageSize) : 0;

  // if (pageDataCount === 1) return null;
  const pageData = _.range(1, pageDataCount + 1);
  // console.log(pageDataCount);
  const pagination = (pageNo) => {
    setCurrentDataPage(pageNo);
    const startIndex = (pageNo - 1) * pageSize;
    const fetchResults = _(Object.values(users))
      .slice(startIndex)
      .take(pageSize)
      .value();
    setPaginatedData(fetchResults);
  };

  return (
    <>
      <main className="md:pt-20 pt-6-mt-2">
        <div className="mx-auto p-2">
          <div className="flex flex-wrap flex-row">
            <div className="flex-shrink max-w-full md:px-4 w-full mb-6">
              <div className="p-2 md:p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg h-full">
                <div className="flex flex-wrap flex-row -mx-4">
                  <div className="flex-shrink max-w-full px-4 w-full">
                    <div className="w-full mb-6 overflow-x-auto">
                      <div class="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                        <div class="flex items-center justify-between">
                          <Link to={"/secCreateMember"} title="link">
                            <button
                              type="button"
                              class="hidden px-8 py-2 font-bold leading-normal text-center text-white align-middle transition-all ease-in border-0 rounded-lg shadow-md cursor-pointer text-xs bg-green-500 lg:block tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                            >
                              Add New Member +{" "}
                            </button>
                          </Link>
                          <DownloadTableExcel
                            filename={`${secSHG} Members`}
                            sheet="users"
                            currentTableRef={userRef.current}
                          >
                            <button
                              type="button"
                              class="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                            >
                              Export Excel <i className="fas fa-download"></i>
                            </button>
                          </DownloadTableExcel>
                        </div>
                      </div>
                      <table
                        className="table-sorter table-bordered w-full text-left text-gray-600 dark:text-gray-400"
                        ref={userRef}
                      >
                        <thead>
                          <tr className="bg-gray-100 dark:bg-gray-900 dark:bg-opacity-40">
                            <th
                              className="hidden md:table-cell"
                              data-sortable="false"
                            >
                              <input
                                id="check_all"
                                type="checkbox"
                                className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-700"
                              />
                            </th>
                            <th className="hidden md:table-cell">Image</th>
                            <th className="hidden md:table-cell">Name</th>
                            <th className="hidden md:table-cell">Role</th>
                            <th className="hidden md:table-cell">Contacts</th>
                            <th className="hidden md:table-cell">Status</th>
                            <th
                              className="hidden md:table-cell"
                              data-sortable="false"
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            dataUsers
                          ) : (
                            <tr className="flex justify-center  items-center h-40 w-full">
                              <td className="w-full mx-auto ">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="50"
                                  height="50"
                                  fill="currentColor"
                                  className="bi bi-arrow-repeat animate-spin"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                  <path d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                                </svg>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div class="bg-white dark:bg-gray-700 p-4 flex items-center flex-wrap">
                        <nav aria-label="Page navigation">
                          <ul class="inline-flex">
                            {pageData.map((page) => (
                              <li key={page}>
                                <button
                                  onClick={() => pagination(page)}
                                  className={
                                    page === currentDataPage
                                      ? "h-10 px-5 text-white transition-colors duration-150 bg-green-600 border border-r-0 border-green-600 focus:shadow-outline"
                                      : "h-10 px-5 text-green-600 transition-colors duration-150 focus:shadow-outline hover:bg-green-100"
                                  }
                                >
                                  {page}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default SecMembers;
