import React, { useState ,useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";
import { RiEyeCloseLine, RiEyeLine, RiCheckboxCircleLine, RiErrorWarningLine } from 'react-icons/ri';


function CreateOfficial() {
  const navigate = useNavigate();
  const token = localStorage.getItem("userToken");

  const { account_name } = useParams();

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [mobile_no, setMobile] = useState("");
  const [id_no, setIDNo] = useState("");
  const [role, setRole] = useState("chairman");
  const [cpassword, setCPassword] = useState("");
  const [allErr, setallErr] = useState(false);
  const [userErr, setuserErr] = useState(false);
  const [passwordErr, setpasswordErr] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);


     // validated states
     const [lowerValidated, setLowerValidated]=useState(false);
     const [upperValidated, setUpperValidated]=useState(false);
     const [numberValidated, setNumberValidated]=useState(false);
     const [specialValidated, setSpecialValidated]=useState(false);
     const [lengthValidated, setLengthValidated]=useState(false);
  
    useEffect(() => {
      validateForm();
    }, 
    [
      email,
      id_no,
      mobile_no,
      name,
      password,
      role,
      cpassword
    ]);
  
    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
  
    const [showPassword, setShowPassword] = useState(false);
  
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    
    const validatePassword = (password) => {
      const lower = new RegExp('(?=.*[a-z])');
      const upper = new RegExp('(?=.*[A-Z])');
      const number = new RegExp('(?=.*[0-9])');
      const special = new RegExp('(?=.*[!@#\$%\^&\*])');
      const length = new RegExp('(?=.{8,})')
      if(lower.test(password)){
        setLowerValidated(true);
      }
      else{
        setLowerValidated(false);
      }
      if(upper.test(password)){
        setUpperValidated(true);
      }
      else{
        setUpperValidated(false);
      }
      if(number.test(password)){
        setNumberValidated(true);
      }
      else{
        setNumberValidated(false);
      }
      if(special.test(password)){
        setSpecialValidated(true);
      }
      else{
        setSpecialValidated(false);
      }
      if(length.test(password)){
        setLengthValidated(true);
      }
      else{
        setLengthValidated(false);
      }
    };
    
    const validateName = (name) => {
      const nameRegex = /^[A-Za-z0-9]+$/;
      return nameRegex.test(name);
    };
    
    const validateMobileNo = (mobileNo) => {
      const mobileNoRegex = /^[0-9]+$/;
      return mobileNoRegex.test(mobileNo);
    };
    
    const validateIdNo = (idNo) => {
      const idNoRegex = /^[0-9]+$/;
      return idNoRegex.test(idNo);
    };
    const validateForm = () => {
      let errors = {};
    
      if (!mobile_no) {
        errors.mobile_no = 'Mobile is required.';
      } else if (!validateMobileNo(mobile_no)) {
        alert('Invalid mobile number character');
        errors.mobile_no = 'Invalid mobile number';
      }
    
      if (!password) {
        errors.password = 'Password is required.';
      } else if (validatePassword(password)) {
        errors.password = 'Password must be at least 8 characters.';
      }
    
      if (!id_no) {
        errors.id_no = 'ID is required.';
      } else if (!validateIdNo(id_no)) {
        alert('Invalid ID number character');
        errors.id_no = 'Invalid ID format.';
      }
    
      if (!name) {
        errors.name = 'Name is required.';
      } else if (!validateName(name)) {
        errors.name = 'Invalid name format.';
      }
    
      if (!email) {
        errors.email = 'Email is required.';
      } else if (!validateEmail(email)) {
        errors.email = 'Invalid email address.';
      }
    
      if (!role) {
        errors.role = 'Role is required.';
      }
    
      if (!cpassword) {
        errors.cpassword = 'Confirm password is required.';
      } else if (password !== cpassword) {
        errors.cpassword = 'Passwords do not match.';
      }
    
      setErrors(errors);
      setIsFormValid(Object.keys(errors).length === 0);
      
    };

  const postData = (e) => {
    e.preventDefault();

    if (isFormValid) {
      setLoading(true);
      Axios.post(
        "https://us-central1-chillimbasaffe.cloudfunctions.net/Signup",
        {
          message: {
            access_token: token,
            mobile_no,
            id_no,
            name,
            email,
            role,
            password,
            cpassword,
          },
        }
      )
        .then((res) => {
          //   console.log("Posting Data From::", res.data);

          if (res.data.code === 555) {
            Axios.post(
              "https://us-central1-chillimbasaffe.cloudfunctions.net/linkmembertoaccount",
              {
                message: {
                  access_token: token,
                  mobile_no: mobile_no,
                  accounts: [account_name],
                  role: role,
                },
              }
            )
              .then((res) => {
                setLoading(false);
                // console.log("Posting SHG Link Data From::", res.data.response);
                alert(res.data.response);
                navigate(`/sghView/${account_name}`);
              })
              .catch((err) => console.log(err));
          } else if (res.data.code === 606) {
            setuserErr(true);
          } else if (res.data.code === 505) {
            setpasswordErr(true);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.message);
        });
    } else {
      setallErr(true);
    }
  };

  return (
    <>
      <div
        className={`${
          !loading && "hidden"
        } w-full h-full fixed  top-0 left-0 bg-white opacity-75 z-50`}
      >
        <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
          <i className="fas fa-circle-notch fa-spin fa-5x"></i>
        </span>
      </div>
      <main className="pt-20 -mt-2">
        <div className="mx-auto p-2">
          <div className="flex flex-wrap flex-row">
            <div className="flex-shrink max-w-full px-4 w-full">
              <p className="text-xl font-bold mt-3 mb-5 dark:text-gray-200">
                Add New official to {account_name}
              </p>
            </div>
            <div className="flex-shrink mx-auto max-w-full px-4 w-full lg:w-2/3 mb-6">
              <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg mb-6">
                <div className="flex-1 p-6 bg-gray-100 dark:bg-gray-900 bg-opacity-60 dark:bg-opacity-20">
                  <div className="p-6 sm:p-8">
                    <div className="text-center">
                      <h2 className="text-2xl font-semibold text-gray-200 px-4 ">
                        <div className="text-gray-700  dark:text-gray-200">
                          Register Member
                        </div>
                      </h2>
                    </div>
                    <div
                      className={`${
                        !allErr && "hidden"
                      } bg-orange-100 my-5    border-t-4 border-orange-500 rounded-b text-orange-900 px-4 py-3 shadow-md`}
                      role="alert"
                    >
                      <div className="flex">
                        <div className="py-1">
                          <svg
                            className="fill-current h-6 w-6 text-orange-500 mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                          </svg>
                        </div>
                        <div>
                          <p className="font-bold">
                            Error Occured While Registering
                          </p>
                          <p className="text-sm">* All Fields are Required</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${
                        !userErr && "hidden"
                      } bg-yellow-100 my-5    border-t-4 border-yellow-500 rounded-b text-yellow-900 px-4 py-3 shadow-md`}
                      role="alert"
                    >
                      <div className="flex">
                        <div className="py-1">
                          <svg
                            className="fill-current h-6 w-6 text-yellow-500 mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                          </svg>
                        </div>
                        <div>
                          <p className="font-bold">
                            Error Occured While Registering
                          </p>
                          <p className="text-sm">User Mobile No. Exists</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${
                        !passwordErr && "hidden"
                      } bg-red-100 my-5    border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md`}
                      role="alert"
                    >
                      <div className="flex">
                        <div className="py-1">
                          <svg
                            className="fill-current h-6 w-6 text-red-500 mr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                          </svg>
                        </div>
                        <div>
                          <p className="font-bold">
                            Error Occured While Registering
                          </p>
                          <p className="text-sm">password don't match</p>
                        </div>
                      </div>
                    </div>

                    <hr className="block w-12 h-0.5 mx-auto my-5 bg-gray-700 border-gray-700" />
                    <form>
                      <div className="mb-6">
                        <label className="inline-block mb-2 text-gray-700 dark:text-gray-200">
                          Mobile No.
                        </label>
                        <input
                          name="mobile"
                          id="inputmobile"
                          className={`${
                            userErr &&
                            " border-yellow-500 border text-yellow-900"
                          } w-full leading-5 border   relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                          value={mobile_no}
                          aria-label="mobile"
                          type="text"
                          onChange={(e) => {
                            setMobile(e.target.value);
                            setuserErr(false);
                            setallErr(false);
                          }}
                        />
                          {errors.mobile_no && <p className='text-red-700 text-sm mt-1'>{errors.mobile_no}</p>}

                      </div>
                      <div className="mb-6">
                        <label className="inline-block mb-2 text-gray-700 dark:text-gray-200">
                          ID No.
                        </label>
                        <input
                          name="idNo"
                          id="inputidNo"
                          className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 dark:text-gray-200 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                          value={id_no}
                          aria-label="idNo"
                          type="text"
                          onChange={(e) => {
                            setIDNo(e.target.value);
                            setallErr(false);
                          }}
                          
                        />
                          {errors.id_no && <p className='text-red-700 text-sm mt-1'>{errors.id_no}</p>}

                      </div>
                      <div className="mb-6">
                        <label className="inline-block mb-2 text-gray-700 dark:text-gray-200">
                          UserName
                        </label>
                        <input
                          name="name"
                          id="inputname"
                          className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 dark:text-gray-200 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                          value={name}
                          aria-label="name"
                          type="name"
                          onChange={(e) => {
                            setName(e.target.value);
                            setallErr(false);
                          }}
                        />
                          {errors.name && <p className='text-red-700 text-sm mt-1'>{errors.name}</p>}

                      </div>
                      <div className="mb-6">
                        <label className="inline-block mb-2 text-gray-700 dark:text-gray-200">
                          Email
                        </label>
                        <input
                          name="email"
                          id="inputemail"
                          className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 dark:text-gray-200 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                          value={email}
                          aria-label="email"
                          type="email"
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setallErr(false);
                          }}
                        />
                          {errors.email && <p className='text-red-700 text-sm mt-1'>{errors.email}</p>}

                      </div>

                      <div className="mb-6">
                        <label className="inline-block mb-2 text-gray-700 dark:text-gray-200">
                          Select User Role
                        </label>
                        <select
                          name="role"
                          id="inputrole"
                          className="w-full leading-5 relative py-2 px-4 rounded text-gray-800 dark:text-gray-200 bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                          value={role}
                          aria-label="role"
                          onChange={(e) => {
                            setRole(e.target.value);
                            setallErr(false);
                          }}
                        >
                          <option value="chairman">Chairman</option>
                          <option value="secretary">Secretary</option>
                          <option value="treasurer">Treasurer</option>
                        </select>
                        {errors.role && <p className='text-red-700 text-sm mt-1'>{errors.role}</p>}
                      </div>

                      <div className="mb-6">
                        <div className="flex flex-wrap flex-row">
                          <div className="flex-shrink max-w-full w-1/2">
                            <label className="inline-block mb-2 text-gray-700 dark:text-gray-200">
                              Password
                            </label>
                          </div>
                        </div>
                        <div className="relative">
                        <input
                          className="w-full leading-5 py-2 px-4 rounded text-gray-800  bg-white border border-gray-300 overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:border-gray-600"
                          type={showPassword ? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" onClick={togglePasswordVisibility}>
                          {showPassword ? <RiEyeCloseLine size={18} /> : <RiEyeLine size={18} />}
                        </span>
                      </div>
                        <main className="tracker-box">
                          <>
                          <div  className={lowerValidated ? "validated" : "not-validated"} style={{ display: 'flex' }}>
                            {lowerValidated ? (
                              <span className="list-icon green">
                                <RiCheckboxCircleLine  />
                              </span>
                            ) : (
                              <span className="list-icon">
                                <RiErrorWarningLine />
                              </span>
                            )}
                            At least one lowercase letter
                          </div>
                          <div className={upperValidated ? "validated" : "not-validated"} style={{ display: 'flex' }}>
                            {upperValidated ? (
                              <span className="list-icon green">
                              <RiCheckboxCircleLine  />
                              </span>
                            ) : (
                              <span className="list-icon">
                              <RiErrorWarningLine />
                              </span>
                            )}
                            At least one uppercase letter
                          </div>
                          <div className={numberValidated ? "validated" : "not-validated"} style={{ display: 'flex' }}>
                            {numberValidated ? (
                              <span className="list-icon green">
                              <RiCheckboxCircleLine  />
                              </span>
                            ) : (
                              <span className="list-icon">
                                  <RiErrorWarningLine />
                              </span>
                            )}
                            At least one number
                          </div>
                          <div className={specialValidated ? "validated" : "not-validated"} style={{ display: 'flex' }}>
                            {specialValidated ? (
                              <span className="list-icon green">
                                <RiCheckboxCircleLine  />
                              </span>
                            ) : (
                              <span className="list-icon">
                                  <RiErrorWarningLine />
                              </span>
                            )}
                            At least one special character
                          </div>
                          <div className={lengthValidated ? "validated" : "not-validated"} style={{ display: 'flex' }}>
                            {lengthValidated ? (
                              <span className="list-icon green">
                                <RiCheckboxCircleLine  />
                              </span>
                            ) : (
                              <span className="list-icon">
                                <RiErrorWarningLine />
                              </span>
                            )}
                            At least 8 characters
                          </div>
                          </>
                          
                        </main>
                      </div>
                      <div className="mb-6">
                        <div className="flex flex-wrap flex-row">
                          <div className="flex-shrink max-w-full w-1/2">
                            <label className="inline-block  mb-2 text-gray-700 dark:text-gray-200">
                              Confirm Password
                            </label>
                          </div>
                        </div>
                        <div className="relative">
                        <input
                          id="inputcpass"
                          className={`${
                            passwordErr && " border-red-500 border text-red-900"
                          } w-full leading-5 border  relative py-2 px-4 rounded   overflow-x-auto focus:outline-none focus:border-gray-400 focus:ring-0 dark:text-gray-300 dark:bg-gray-700`}
                          aria-label="cpassword"
                          type="password"
                          value={cpassword}
                          onChange={(e) => {
                            setCPassword(e.target.value);
                            setpasswordErr(false);
                            setallErr(false);
                          }}
                        />
                        <span
                          className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <RiEyeCloseLine size={18} />
                          ) : (
                            <RiEyeLine size={18} />
                          )}
                        </span>
                        </div>
                        {errors.cpassword && <p className='text-red-700 text-sm mt-1'>{errors.cpassword}</p>}

                      </div>

                      <div className="grid">
                        <button
                          onClick={postData}
                          className="py-2 px-4 inline-block text-center rounded leading-5 text-gray-100 bg-green-500 border border-green-500 hover:text-white hover:bg-green-600 hover:ring-0 hover:border-green-600 focus:bg-green-600 focus:border-green-600 focus:outline-none focus:ring-0"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            className="inline-block w-4 h-4 mr-2 bi bi-box-arrow-in-right"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
                            <path d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                          </svg>
                          Create Member
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default CreateOfficial;
