import React from "react";

// Modify path,title, icons, subnav according to project
export const SecData = [
  {
    title: "Dashboard",
    path: "/secDashboard",
    icon: <i className="fa-solid fa-home"></i>,
  },

  {
    title: "Sacco Members",
    path: "/secMembers",
    icon: <i className="fa-solid fa-user"></i>,
  },

  {
    title: "Sacco Savings",
    path: "/secShgSavings",
    icon: <i className="fa-solid fa-sack-dollar"></i>,
  },
];
