import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

function UserDashboard() {
  const [totalUsers, setTotalUsers] = useState([]);
  const userSHG = localStorage.getItem("userSHG");
  const token = localStorage.getItem("userToken");
  const mobile_no = localStorage.getItem("userMobile");
  const [savings, setSavings] = useState();
  const [shgSavings, setSHGSavings] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    checkSHG();
  }, []);

  const checkSHG = () => {
    if (!localStorage.getItem("userSHG")) {
      navigate("/userSelectSHG");
    }
  };

  useEffect(() => {
    Axios.post(
      "https://us-central1-safechama.cloudfunctions.net/statistics",
      {
        message: {
          access_token: token,
          option: "totalsavingsofuser",
          mobile_no: mobile_no,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting DAta From::", res.data.data);
        setSavings(res.data.data);
      })
      .catch((err) => console.log(err));

    Axios.post(
      "https://us-east4-safechama.cloudfunctions.net/getallsavings",
      { message: { access_token: token } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting DAta From::", res.data.data1);
        setSHGSavings(res.data.data1);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    Axios.post(
      " https://us-central1-safechama.cloudfunctions.net/statistics",
      { message: { access_token: token, option: "totalnouserShg" } },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        // console.log("GEtting DAta From::", res.data.data);
        setTotalUsers(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const mySavings = Object.values(shgSavings);
  const dataSavings = mySavings.map((data, index) => {
    if (data.account_name == userSHG) {
      return <span key={index}>{data.amount}</span>;
    }
  });

  const myTotalUsers = Object.values(totalUsers);
  const dataUsers = myTotalUsers.map((data, index) => {
    if (data.accountname == userSHG) {
      return <span key={index}>{data.no_of_members}</span>;
    }
  });

  return (
    <>
      <main className="md:m-5 m-2 ">
        <div className="mx-auto ">
          <div className="flex-shrink max-w-full  w-full">
            <a
              className="flex bg-green-600 items-center justify-between p-4 mb-8 text-sm font-semibold text-gray-200  rounded-lg shadow-md focus:outline-none focus:shadow-outline-purple"
              href="./"
            >
              <div className="flex items-center">
                <svg
                  className="w-5 h-5 mr-2"
                  fill="#ced8cf"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                </svg>
                <span className="flex justify-between items-center">
                  <span className="hidden sm:block mr-1">Welcome To </span> User{" "}
                  <span className="hidden sm:block ml-1"> Dashboard </span>
                </span>
              </div>
              <span>
                <img
                  className="w-12"
                  src={require("../../img/logo.png")}
                  alt="logo"
                />
              </span>
              <span>
                {" "}
                <i className="fas fa-arrow-right"></i>
              </span>
            </a>
          </div>
          {/* Welcome */}

          {/* row */}
          <div className="flex flex-wrap flex-row text-gray-700 dark:text-gray-200">
            <div className="flex-shrink max-w-full  w-full">
              <p className="text-xl font-bold mt-3 mb-5">
                {userSHG} Member Summary
              </p>
            </div>

            <div className="flex-shrink max-w-full sm:px-4 w-full sm:w-1/2  md:w-1/3  mb-6">
              <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg h-full">
                <div className="pt-6 px-6 relative text-sm font-semibold">
                  Total Amount of Member Savings
                  <div className="float-right text-green-500">+</div>
                </div>
                <div className="flex flex-row justify-between px-6 py-4">
                  <div className="self-center pt-3 w-14 h-14 rounded-full text-green-500 bg-green-100 dark:bg-green-900 dark:bg-opacity-40 relative text-center">
                    <span className="text-3xl ">
                      <i class="fa-solid fa-sack-dollar"></i>
                    </span>
                  </div>
                  <h2 className="self-center text-3xl"> {savings}</h2>
                </div>
                <div className="px-6 pb-3">
                  <span className="hover:text-indigo-500 text-lg font-bold">
                    KES
                  </span>
                </div>
              </div>
            </div>
            <div className="flex-shrink max-w-full sm:px-4 w-full sm:w-1/2  md:w-1/3  mb-6">
              <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg h-full">
                <div className="pt-6 px-6 relative text-sm font-semibold">
                  Total Amount of Group Savings
                  <div className="float-right text-green-500">+</div>
                </div>
                <div className="flex flex-row justify-between px-6 py-4">
                  <div className="self-center pt-3 w-14 h-14 rounded-full text-green-500 bg-green-100 dark:bg-green-900 dark:bg-opacity-40 relative text-center">
                    <span className="text-3xl ">
                      <i class="fa-solid fa-sack-dollar"></i>
                    </span>
                  </div>
                  <h2 className="self-center text-3xl"> {dataSavings}</h2>
                </div>
                <div className="px-6 pb-3">
                  <span className="hover:text-indigo-500 text-lg font-bold">
                    KES
                  </span>
                </div>
              </div>
            </div>
            <div className="flex-shrink max-w-full sm:px-4 w-full sm:w-1/2  md:w-1/3  mb-6">
              <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg h-full">
                <div className="pt-6 px-6 relative text-sm font-semibold">
                  Total No. of Group Members
                  <div className="float-right text-green-500">+</div>
                </div>
                <div className="flex flex-row justify-between px-6 py-4">
                  <div className="self-center pt-3 w-14 h-14 rounded-full text-yellow-500 bg-yellow-100 dark:bg-yellow-900 dark:bg-opacity-40 relative text-center">
                    <span className="text-3xl ">
                      <i className="fas fa-users"></i>
                    </span>
                  </div>
                  <h2 className="self-center text-3xl"> {dataUsers}</h2>
                </div>
                <div className="px-6 pb-3">
                  <span className="hover:text-indigo-500 text-lg font-bold">
                    No.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default UserDashboard;
