import React from "react";
import Navbar from "./Navbar";
import SidebarWithSubmenu from "./SidebarWithSubmenu";

const Layout = ({ children }) => {
  const currentYear = new Date().getFullYear();
  if (localStorage.getItem("userMobile")) {
    return (
      <>
        <div className="">
          <Navbar />

          <div className="lg:flex flex-wrap">
            <div className="w-full lg:w-[25%]">
              <SidebarWithSubmenu />
            </div>
            <div className="w-full lg:w-[75%] ">
              {children}
              <footer className="pt-4  ml-auto md:mt-24 border-gray-200 bg-white dark:bg-gray-700  mx-2 px-2 py-2.5 rounded-xl">
                <div className="w-full px-6 mx-auto ">
                  <div className="flex flex-wrap items-center -mx-3 lg:justify-center">
                    <div className="w-full px-3  mx-auto  mb-6 shrink-0 lg:mb-0 lg:w-1/2 ">
                      <div className="text-sm flex flex-wrap items-center -mx-3 lg:justify-center leading-normal text-center text-slate-500 lg:text-left">
                        © {currentYear}. All Rights Reserved. Msimbo Dev
                        <img
                          src={require("./img/footer.PNG")}
                          alt=""
                          className="w-12"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="w-full ">{children}</div>
      </>
    );
  }
};

export default Layout;
