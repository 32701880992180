import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { authContext } from "../Context/AuthContext";

import { Disclosure, Transition } from "@headlessui/react";

function Home() {
  const { logged, setLogged } = useContext(authContext);
  const navigate = useNavigate();

  useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = () => {
    if (localStorage.getItem("userMobile")) {
      setLogged(true);
      const useRole = localStorage.getItem("userRole");

      if (useRole === "admin") {
        navigate("/dashboard");
      } else if (useRole === "chairman") {
        navigate("/chairDashboard");
      } else if (useRole === "secretary") {
        navigate("/secDashboard");
      } else if (useRole === "treasurer") {
        navigate("/tresDashboard");
      } else if (useRole === "affiliate") {
        navigate("/affDashboard");
      } else if (useRole === "user") {
        navigate("/userDashboard");
      }
    } else {
      navigate("/");
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="main-menu sticky top-0 z-40  ">
        <div className="mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 ">
          <nav className="nav">
            <div className="nav__data">
              <Link to="/" className="nav__logo">
                <img
                  src={require("../img/logo.png")}
                  className=" w-44 md:w-96"
                  alt="logo"
                />
              </Link>
              <div className="flex md:hidden">
                <button
                  onClick={handleOpen}
                  type="button"
                  className="text-gray-900"
                  aria-expanded="expanded"
                >
                  <span
                    aria-hidden="true"
                    className={`${open && "hidden"} px-1`}
                  >
                    <svg
                      className="w-7 h-7"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                  </span>

                  <span
                    aria-hidden="true"
                    className={`${!open && "hidden"} px-1`}
                  >
                    <span className="w-7 h-7">
                      <i className="fas fa-close"></i>
                    </span>
                  </span>
                </button>
              </div>
            </div>
            <div className="nav__menu" id="nav-menu">
              <ul className="nav__list">
                <li className="dropdown__item">
                  <Link to="/" className="nav__link ">
                    Home
                  </Link>
                </li>
                <li className="dropdown__item">
                  <a href="#features" className="nav__link ">
                    Features
                  </a>
                </li>
                <li className="dropdown__item">
                  <a href="#works" className="nav__link ">
                    How it Works
                  </a>
                </li>
                <li className="dropdown__item">
                  <a href="#" className="nav__link ">
                    Faq
                  </a>
                </li>
              </ul>
            </div>
            <div className="hidden xl:block">
              <Link
                to="/signIn"
                className="bg-green-600  hover:bg-green-700 group text-white px-6 py-4 font-medium rounded-lg inline-flex justify-center items-center transition"
              >
                <span>Sign In</span>
                <svg
                  className="stroke-white transform translate transition-transform group-hover:translate-x-2"
                  width="24"
                  height="25"
                  viewbox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 16.5L18 12.5M18 12.5L14 8.5M18 12.5L6 12.5"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Link>
            </div>
          </nav>

          <nav className={`${!open && "hidden"} md:hidden`}>
            <div className="flex flex-col pt-8 pb-4 px-8 space-y-6 -translate-y-3 rounded-b-2xl bg-white">
              <Link
                to="/"
                title=""
                className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
              >
                {" "}
                Home{" "}
              </Link>

              <a
                href="#features"
                title=""
                className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
              >
                {" "}
                Features{" "}
              </a>

              <a
                href="#work"
                title=""
                className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
              >
                {" "}
                How it Works{" "}
              </a>

              <Link
                to="/faq"
                className="text-base font-normal text-gray-400 transition-all duration-200 hover:text-white"
              >
                {" "}
                Faq{" "}
              </Link>

              <div className="relative inline-flex items-center justify-center group">
                <Link
                  to="/signIn"
                  className="bg-green-600  hover:bg-green-700 group text-white px-6 py-4 font-medium rounded-lg inline-flex justify-center items-center transition"
                >
                  <span>Sign In</span>
                  <svg
                    className="stroke-white transform translate transition-transform group-hover:translate-x-2"
                    width="24"
                    height="25"
                    viewbox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 16.5L18 12.5M18 12.5L14 8.5M18 12.5L6 12.5"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <main>
        <section className="pt-32  hero-bg">
          <div className="mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="grid lg:grid-cols-2 gap-10 place-items-center ">
              <div className="lg:col-span-1">
                <h1 className="text-3xl leading-normal xl:text-[46px] xl:leading-snug mb-8  font-bold text-interface-100">
                  Digital Financial inclusion through USSD
                </h1>
                <p className="text-xl lg:text-2xl text-interface-200 mb-4">
                  Maano Digi Save is a digital platform that enables savings and
                  credit for groups or Sacco that are underbanked, whether they
                  are table banking, merry-go-round, or investments; formal or
                  informal. It helps them access financial services that are not
                  offered by the conventional banking system.
                </p>
                <div className="flex items-center gap-8 mt-10">
                  <a
                    href="#contact"
                    className="bg-green-600  hover:bg-green-700 transition-all group text-white px-6 py-4 rounded-lg inline-flex justify-center items-center"
                  >
                    <span>Contact Us</span>
                    <svg
                      className="stroke-white transform translate transition-transform group-hover:translate-x-2"
                      width="24"
                      height="25"
                      viewbox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14 16.5L18 12.5M18 12.5L14 8.5M18 12.5L6 12.5"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>

              <div className="lg:col-span-1 ">
                <div className="relative">
                  <img src={require("../img/banner2.png")} alt="logo" />
                  <div className="hidden md:block">
                    <div className=" absolute bottom-40 left-5">
                      <img src={require("../img/code.png")} alt="logo" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src={require("../img/arrow-png.png")}
            className="bottom-[30px] hidden xl:block -left-16 absolute"
            alt="logo"
          />
        </section>

        <section
          className="pt-20 lg:pt-120 lg:pb-0 pb-120 bg-[#F7F7FD] features-bg"
          id="features"
        >
          <div className="mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 xl:gap-20">
              <div className="relative">
                <img
                  src={require("../img/ussd.png")}
                  className="w-full"
                  alt="logo"
                />
              </div>

              <div>
                <h2 className="text-interface-500 font-bold text-xl md:text-4xl leading-normal xl:text-5xl capitalize xl:leading-snug mb-4">
                  USSD
                  <span className="relative">
                    <span className="relative z-20"> Features</span>
                  </span>
                </h2>
                <article className="my-4">
                  <div className="relative transition duration-200 cursor-pointer px-7 py-8 bg-white group hover:bg-green-600 shadow-customThree rounded-custom overflow-hidden">
                    <div className="w-[70px] h-[70px] rounded-full bg-green-600 group-hover:bg-white group-hover:bg-opacity-10 bg-opacity-10 pl-5 pt-3 mb-6 text-3xl">
                      <img src={require("../img/savings.png")} alt="logo" />
                    </div>
                    <h3 className="mb-6 font-semibold text-2xl text-interface-100 group-hover:text-white">
                      1. Savings
                    </h3>
                    <p className="pb-6 text-interface-200 group-hover:text-white text-lg">
                      Sacco members can contribute to their savings and Sacco
                      savings via USSD from anywhere
                    </p>
                  </div>
                </article>

                <article className="my-4">
                  <div className="relative transition duration-200 cursor-pointer px-7 py-8 bg-white group hover:bg-green-600 shadow-customThree rounded-custom overflow-hidden">
                    <div className="w-[70px] h-[70px] rounded-full bg-green-600 group-hover:bg-white group-hover:bg-opacity-10 bg-opacity-10 pl-5 pt-3 mb-6 text-3xl">
                      <img src={require("../img/balance.png")} alt="logo" />
                    </div>
                    <h3 className="mb-6 font-semibold text-2xl text-interface-100 group-hover:text-white">
                      2. Balance
                    </h3>
                    <p className="pb-6 text-interface-200 group-hover:text-white text-lg">
                      Sacco members can query their balance at anytime to know
                      their individual and group performance
                    </p>
                  </div>
                </article>

                <article className="my-4">
                  <div className="relative transition duration-200 cursor-pointer px-7 py-8 bg-white group hover:bg-green-600 shadow-customThree rounded-custom overflow-hidden">
                    <div className="w-[70px] h-[70px] rounded-full bg-green-600 group-hover:bg-white group-hover:bg-opacity-10 bg-opacity-10 pl-5 pt-3 mb-6 text-3xl">
                      <img src={require("../img/loan.png")} alt="logo" />
                    </div>
                    <h3 className="mb-6 font-semibold text-2xl text-interface-100 group-hover:text-white">
                      3. Loans
                    </h3>
                    <p className="pb-6 text-interface-200 group-hover:text-white text-lg">
                      Maano Digi Save gives members limited loan limits based on
                      their interaction with the system at affordable rates of
                      2.5% interest on reducing balance
                    </p>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>
        <section className="py-20 lg:py-120 bg-[#F7F7FD]">
          <img src={require("../img/dashboard.PNG")} alt="logo" />
        </section>

        <section className="py-20 lg:py-120 hero-bg" id="works">
          <div className="mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="mb-14 text-center">
              <h2 className="text-interface-100 font-bold text-4xl leading-normal xl:text-5xl capitalize xl:leading-snug">
                How
                <span className="relative">
                  <span className="relative z-20"> It Works</span>
                </span>
              </h2>
            </div>

            <div className=" p-5 relative mt-12 lg:mt-20 ">
              <div className="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28">
                <img
                  className="w-full"
                  src="https://cdn.rareblocks.xyz/collection/celebration/images/steps/2/curved-dotted-line.svg"
                  alt="logo"
                />
              </div>

              <div className="relative grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12">
                <div>
                  <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                    <span className="text-xl font-semibold text-gray-700">
                      1
                    </span>
                  </div>
                  <img
                    className="w-full rounded-lg "
                    src={require("../img/dial.png")}
                    alt="ussd"
                  />
                  <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">
                    Dial *377#
                  </h3>
                </div>

                <div>
                  <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                    <span className="text-xl font-semibold text-gray-700">
                      2
                    </span>
                  </div>
                  <img
                    className="w-full rounded-lg "
                    src={require("../img/select.png")}
                    alt="ussd"
                  />
                  <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">
                    Select Maano Digi Save
                  </h3>
                </div>

                <div>
                  <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                    <span className="text-xl font-semibold text-gray-700">
                      3
                    </span>
                  </div>
                  <img
                    className="w-full rounded-lg "
                    src={require("../img/ussd.png")}
                    alt="ussd"
                  />
                  <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">
                    Access Dashboard
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-20 lg:py-120 testimonial-bg">
          <div className="mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="pt-20">
              <div className="max-w-2xl mx-auto text-center mb-10">
                <h4 className="text-green-600 text-lg font-medium uppercase mb-2">
                  CUSTOMER REVIEWS
                </h4>
                <h2 className="text-interface-100 font-bold text-4xl leading-normal xl:text-5xl xl:leading-snug">
                  What Our Customers Say
                </h2>
              </div>
              <div className="relative grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12">
                <div className="rounded bg-white border border-[#ECEAFF] shadow-customFour p-7 relative">
                  <ul className="flex space-x-2 mb-6">
                    <li className="text-porange-500">
                      <i className="fa-solid fa-star"></i>
                    </li>
                    <li className="text-porange-500">
                      <i className="fa-solid fa-star"></i>
                    </li>
                    <li className="text-porange-500">
                      <i className="fa-solid fa-star"></i>
                    </li>
                    <li className="text-porange-500">
                      <i className="fa-solid fa-star"></i>
                    </li>
                    <li className="text-porange-500">
                      <i className="fa-solid fa-star"></i>
                    </li>
                  </ul>
                  <blockquote className="pb-8 block text-xl text-pcolor-500 italic">
                    I have registered my Sacco with Maano Digi Save and so far
                    they are the best online savings and loan platform I have
                    used, thanks alot for your services.
                  </blockquote>
                  <div className="flex space-x-4">
                    <img
                      src={require("../img/avatar.jpg")}
                      className="w-[120px] h-[100px] rounded-full"
                      alt="profile"
                    />
                    <div>
                      <h4 className="font-semibold text-interface-100 text-xl">
                        Christian
                      </h4>
                      <span className="text-pcolor-500 text-base">Lusaka</span>
                    </div>
                  </div>
                </div>
                <div className="rounded bg-white border border-[#ECEAFF] shadow-customFour p-7 relative">
                  <ul className="flex space-x-2 mb-6">
                    <li className="text-porange-500">
                      <i className="fa-solid fa-star"></i>
                    </li>
                    <li className="text-porange-500">
                      <i className="fa-solid fa-star"></i>
                    </li>
                    <li className="text-porange-500">
                      <i className="fa-solid fa-star"></i>
                    </li>
                    <li className="text-porange-500">
                      <i className="fa-solid fa-star"></i>
                    </li>
                    <li className="text-porange-500">
                      <i className="fa-solid fa-star"></i>
                    </li>
                  </ul>
                  <blockquote className="pb-8 block text-xl text-pcolor-500 italic">
                    I have registered my Sacco with Maano Digi Save and so far
                    they are the best online savings and loan platform I have
                    used, thanks alot for your services.
                  </blockquote>
                  <div className="flex space-x-4">
                    <img
                      src={require("../img/avatar1.jpg")}
                      className="w-[120px] h-[100px] rounded-full"
                      alt="profile"
                    />
                    <div>
                      <h4 className="font-semibold text-interface-100 text-xl">
                        Muhamed
                      </h4>
                      <span className="text-pcolor-500 text-base">Kitwe</span>
                    </div>
                  </div>
                </div>
                <div className="rounded bg-white border border-[#ECEAFF] shadow-customFour p-7 relative">
                  <ul className="flex space-x-2 mb-6">
                    <li className="text-porange-500">
                      <i className="fa-solid fa-star"></i>
                    </li>
                    <li className="text-porange-500">
                      <i className="fa-solid fa-star"></i>
                    </li>
                    <li className="text-porange-500">
                      <i className="fa-solid fa-star"></i>
                    </li>
                    <li className="text-porange-500">
                      <i className="fa-solid fa-star"></i>
                    </li>
                    <li className="text-porange-500">
                      <i className="fa-solid fa-star"></i>
                    </li>
                  </ul>
                  <blockquote className="pb-8 block text-xl text-pcolor-500 italic">
                    I have registered my Sacco with Maano Digi Save and so far
                    they are the best online savings and loan platform I have
                    used, thanks alot for your services.
                  </blockquote>
                  <div className="flex space-x-4">
                    <img
                      src={require("../img/avatar2.jpg")}
                      className="w-[120px] h-[100px] rounded-full"
                      alt="profile"
                    />
                    <div>
                      <h4 className="font-semibold text-interface-100 text-xl">
                        Magret
                      </h4>
                      <span className="text-pcolor-500 text-base">Lusaka</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-10 bg-gray-50 sm:py-16 lg:py-24" id="faq">
          <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div className="max-w-2xl mx-auto text-center">
              <div>
                <h2 className="text-interface-500 font-bold text-4xl leading-normal xl:text-5xl capitalize xl:leading-snug">
                  Why
                  <span className="relative">
                    <span className="relative z-20"> Choose Us</span>
                  </span>
                </h2>
              </div>
            </div>

            <div className="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16 ">
              <Disclosure className="mt-2 ">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                      <span className="flex text-lg font-semibold text-black">
                        Convenience and Efficiency
                      </span>
                      <span
                        className={`${
                          !open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-green-500`}
                      >
                        <i className="fas fa-chevron-up"></i>{" "}
                      </span>
                    </Disclosure.Button>
                    <Disclosure.Panel className=" rounded-lg bg-white px-6 py-6">
                      The platform simplifies Sacco management by automating
                      tasks such as record-keeping, contribution tracking, loan
                      management, and communication. Members can access their
                      Sacco accounts, contribute savings, apply for loans, and
                      receive notifications using their smartphones or
                      computers. This convenience saves time and effort for both
                      Sacco members and administrators.
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure className="mt-2 ">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                      <span className="flex text-lg font-semibold text-black">
                        Transparency and Accountability
                      </span>
                      <span
                        className={`${
                          !open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-green-500`}
                      >
                        <i className="fas fa-chevron-up"></i>{" "}
                      </span>
                    </Disclosure.Button>
                    <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                      Maano Digi Save enhances transparency and accountability
                      within Saccos. It provides a clear record of
                      contributions, loans, repayments, and other financial
                      transactions. This transparency fosters trust among Sacco
                      members and ensures that the financial operations are
                      conducted in a fair and accountable manner.
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
              <Disclosure className="mt-2 ">
                {({ open }) => (
                  <>
                    <Disclosure.Button className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50 flex w-full justify-between rounded-lg px-4 py-2 text-left text-sm font-medium  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75">
                      <span className="flex text-lg font-semibold text-black">
                        Security and Data Privacy
                      </span>
                      <span
                        className={`${
                          !open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-green-500`}
                      >
                        <i className="fas fa-chevron-up"></i>{" "}
                      </span>
                    </Disclosure.Button>
                    <Disclosure.Panel className=" bg-white px-6 py-6 rounded-lg">
                      Maano Digi Save prioritizes the security and privacy of
                      Sacco data. The platform implements robust security
                      measures to protect sensitive information, such as member
                      details and financial transactions, from unauthorized
                      access. By leveraging secure technology, Maano Digi Save
                      provides a safe and reliable platform for managing Sacco
                      finances.
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </section>

        <section
          className="py-10 bg-gray-100 sm:py-16 lg:py-24 features-bg"
          id="contact"
        >
          <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
            <div className="max-w-2xl mx-auto text-center">
              <h2 className="text-3xl font-bold leading-tight text-gray-900 sm:text-4xl lg:text-5xl">
                Contact us
              </h2>
            </div>

            <div className="max-w-5xl mx-auto mt-12 sm:mt-16">
              <div className="grid grid-cols-1 gap-6 px-8 text-center md:px-0 md:grid-cols-3">
                <div className="overflow-hidden bg-white rounded-xl">
                  <div className="p-6">
                    <svg
                      className="flex-shrink-0 w-10 h-10 mx-auto text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
                    <p className="mt-6 text-lg font-medium text-gray-900">
                      Airtel: 0779292198{" "}
                    </p>
                    <p className="mt-1 text-lg font-medium text-gray-900">
                      MTN: 0761000528
                    </p>
                    <p className="mt-1 text-lg font-medium text-gray-900">
                      Zamtel: 0950551919
                    </p>
                  </div>
                </div>

                <div className="overflow-hidden bg-white rounded-xl">
                  <div className="p-6">
                    <svg
                      className="flex-shrink-0 w-10 h-10 mx-auto text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    <p className="mt-6 text-lg font-medium text-gray-900">
                      info@maanodigisave.com
                    </p>
                    <p className="mt-1 text-lg font-medium text-gray-900">
                      help@maanodigisave.com
                    </p>
                  </div>
                </div>

                <div className="overflow-hidden bg-white rounded-xl">
                  <div className="p-6">
                    <svg
                      className="flex-shrink-0 w-10 h-10 mx-auto text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                      <path d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                    <p className="mt-6 text-lg font-medium leading-relaxed text-gray-900">
                      Location: 6 Nyati Close, Rhodespark, Lusaka, Zambia
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-6 overflow-hidden bg-white rounded-xl">
                <div className="px-6 py-12 sm:p-12">
                  <h3 className="text-3xl font-semibold text-center text-gray-900">
                    Send us a message
                  </h3>

                  <form action="#" method="POST" className="mt-14">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-4">
                      <div>
                        <label className="text-base font-medium text-gray-900">
                          {" "}
                          Your name{" "}
                        </label>
                        <div className="mt-2.5 relative">
                          <input
                            type="text"
                            name=""
                            id=""
                            placeholder="Enter your full name"
                            className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-green-600 caret-green-600"
                          />
                        </div>
                      </div>

                      <div>
                        <label className="text-base font-medium text-gray-900">
                          {" "}
                          Email address{" "}
                        </label>
                        <div className="mt-2.5 relative">
                          <input
                            type="email"
                            name=""
                            id=""
                            placeholder="Enter your full name"
                            className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-green-600 caret-green-600"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label className="text-base font-medium text-gray-900">
                          {" "}
                          Message{" "}
                        </label>
                        <div className="mt-2.5 relative">
                          <textarea
                            name=""
                            id=""
                            placeholder=""
                            className="block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md resize-y focus:outline-none focus:border-green-600 caret-green-600"
                            rows="4"
                          ></textarea>
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <button
                          type="button"
                          className="inline-flex items-center justify-center w-full px-4 py-4 mt-2 text-base font-semibold text-white transition-all duration-200 bg-green-600 border border-transparent rounded-md focus:outline-none hover:bg-green-700 focus:bg-green-700"
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="lg:py-120 py-20 border-b border-[#1C2134] footer-bg footer-bg">
          <div className="mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="grid md:grid-cols-3 divide-y md:divide-y-0 lg:divide-x divide-white divide-opacity-10 justify-between gap-10">
              <div>
                <Link to="/" className="block mb-7">
                  <img src={require("../img/logow.png")} alt="logow" />
                </Link>

                <div className="flex space-x-3 mt-7">
                  <a
                    href="#"
                    className="text-interface-200 hover:bg-green-500 hover:text-white transition-all text-lg w-10 h-10 rounded-full bg-white bg-opacity-10 inline-flex justify-center items-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                  <a
                    href="#"
                    className="text-interface-200 hover:bg-green-500 hover:text-white transition-all text-lg w-10 h-10 rounded-full bg-white bg-opacity-10 inline-flex justify-center items-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                  <a
                    href="#"
                    className="text-interface-200 hover:bg-green-500 hover:text-white transition-all text-lg w-10 h-10 rounded-full bg-white bg-opacity-10 inline-flex justify-center items-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                  <a
                    href="#"
                    className="text-interface-200 hover:bg-green-500 hover:text-white transition-all text-lg w-10 h-10 rounded-full bg-white bg-opacity-10 inline-flex justify-center items-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-youtube"></i>
                  </a>
                </div>
              </div>
              <div className=" pt-8 lg:pt-0 lg:pl-12">
                <h4 className="mb-7 text-white text-2xl font-semibold">
                  Links
                </h4>
                <nav>
                  <ul className="space-y-4">
                    <li>
                      <Link
                        to="/"
                        className="font-medium text-base text-white transition hover:text-green-500"
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <a
                        href="#features"
                        className="font-medium text-base text-white transition hover:text-green-500"
                      >
                        Features
                      </a>
                    </li>
                    <li>
                      <a
                        href="#works"
                        className="font-medium text-base text-white transition hover:text-green-500"
                      >
                        How it Works
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="font-medium text-base text-white transition hover:text-green-500"
                      >
                        FAQ
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className=" pt-8 lg:pt-0 lg:pl-12">
                <h4 className="mb-7 text-white text-2xl font-semibold">
                  Official Info:
                </h4>
                <ul className="space-y-3">
                  <li className="flex space-x-2">
                    <svg
                      width="20"
                      height="20"
                      viewbox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10 18.3334C12.8125 18.3334 17.5 13.1651 17.5 9.07409C17.5 4.9831 14.1421 1.66669 10 1.66669C5.85786 1.66669 2.5 4.9831 2.5 9.07409C2.5 13.1651 7.1875 18.3334 10 18.3334ZM10 11.6667C11.3807 11.6667 12.5 10.5474 12.5 9.16669C12.5 7.78598 11.3807 6.66669 10 6.66669C8.61929 6.66669 7.5 7.78598 7.5 9.16669C7.5 10.5474 8.61929 11.6667 10 11.6667Z"
                        fill="#2662FA"
                      />
                    </svg>
                    <p className="text-white">
                      6 Nyati Close, Rhodespark, Lusaka, Zambia
                    </p>
                  </li>
                  <li className="flex space-x-2">
                    <svg
                      width="20"
                      height="20"
                      viewbox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 15.8333V14.4617C17.5 13.7802 17.0851 13.1674 16.4523 12.9143L14.7572 12.2362C13.9524 11.9143 13.0352 12.263 12.6475 13.0383L12.5 13.3333C12.5 13.3333 10.4167 12.9167 8.75 11.25C7.08333 9.58333 6.66667 7.5 6.66667 7.5L6.96168 7.35249C7.73698 6.96484 8.08571 6.04761 7.76379 5.2428L7.08574 3.54768C6.83263 2.91492 6.21979 2.5 5.53828 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667C2.5 11.5305 8.46954 17.5 15.8333 17.5C16.7538 17.5 17.5 16.7538 17.5 15.8333Z"
                        fill="#2662FA"
                      />
                    </svg>
                    <a href="tel:0779292198" className="text-white text-base">
                      Airtel: 0779292198
                    </a>
                  </li>
                  <li className="flex space-x-2">
                    <svg
                      width="20"
                      height="20"
                      viewbox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 15.8333V14.4617C17.5 13.7802 17.0851 13.1674 16.4523 12.9143L14.7572 12.2362C13.9524 11.9143 13.0352 12.263 12.6475 13.0383L12.5 13.3333C12.5 13.3333 10.4167 12.9167 8.75 11.25C7.08333 9.58333 6.66667 7.5 6.66667 7.5L6.96168 7.35249C7.73698 6.96484 8.08571 6.04761 7.76379 5.2428L7.08574 3.54768C6.83263 2.91492 6.21979 2.5 5.53828 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667C2.5 11.5305 8.46954 17.5 15.8333 17.5C16.7538 17.5 17.5 16.7538 17.5 15.8333Z"
                        fill="#2662FA"
                      />
                    </svg>
                    <a href="tel:0761000528" className="text-white text-base">
                      MTN: 0761000528
                    </a>
                  </li>
                  <li className="flex space-x-2">
                    <svg
                      width="20"
                      height="20"
                      viewbox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 15.8333V14.4617C17.5 13.7802 17.0851 13.1674 16.4523 12.9143L14.7572 12.2362C13.9524 11.9143 13.0352 12.263 12.6475 13.0383L12.5 13.3333C12.5 13.3333 10.4167 12.9167 8.75 11.25C7.08333 9.58333 6.66667 7.5 6.66667 7.5L6.96168 7.35249C7.73698 6.96484 8.08571 6.04761 7.76379 5.2428L7.08574 3.54768C6.83263 2.91492 6.21979 2.5 5.53828 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667C2.5 11.5305 8.46954 17.5 15.8333 17.5C16.7538 17.5 17.5 16.7538 17.5 15.8333Z"
                        fill="#2662FA"
                      />
                    </svg>
                    <a href="tel:0950551919" className="text-white text-base">
                      Zamtel: 0950551919
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>

        <section className="px-4 bg-interface-100 py-5">
          <p className="text-xl font-medium text-white text-center">
            &copy; Copyright {currentYear} Msimbo DevOps. All Rights Reserved.
          </p>
        </section>
      </main>
    </>
  );
}

export default Home;
