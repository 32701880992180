import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";

function AffDashboard() {
  const [totalSHG, setTotalSHG] = useState([]);
  const [totalSavings, setTotalSavings] = useState([]);
  const [totalUsers, setTotalUsers] = useState([]);
  const mobile_no = localStorage.getItem("userMobile");
  const token = localStorage.getItem("userToken");

  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      Axios.post(
        "https://us-central1-chillimbasaffe.cloudfunctions.net/statistics",
        {
          message: {
            access_token: token,
            option: "noofSHGaffiliate",
            mobile_no: mobile_no,
          },
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((res) => {
          // console.log("GEtting DAta From::", res.data.data);
          setTotalSHG(res.data.data);
        })
        .catch((err) => console.log(err));

      Axios.post(
        "https://us-central1-chillimbasaffe.cloudfunctions.net/statistics",
        {
          message: {
            access_token: token,
            option: "affliliatenousersgroup",
            mobile_no: mobile_no,
          },
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((res) => {
          // console.log("GEtting DAta From::", res.data);
          setTotalUsers(res.data.totalusers);
        })
        .catch((err) => console.log(err));

      Axios.post(
        "https://us-central1-chillimbasaffe.cloudfunctions.net/statistics",
        {
          message: {
            access_token: token,
            option: "affiliateshgsaving",
            mobile_no: mobile_no,
          },
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((res) => {
          // console.log("GEtting DAta From::", res.data);
          setTotalSavings(res.data.totalsavings);
        })
        .catch((err) => console.log(err));

      return () => {
        effectRan.current = true;
      };
    }
  }, []);

  return (
    <>
      <div className="body-content px-8 py-8 ">
        <div className="flex justify-between items-end flex-wrap">
          <div className="page-title mb-7">
            <h3 className="mb-0 text-4xl">Dashboard</h3>
            <p className="text-textBody m-0">Welcome to your dashboard</p>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6 mb-6">
          <Link
            to={"/affShg"}
            className="hover:scale-105 transition ease-in-out delay-150"
          >
            <div className="widget-item bg-white p-6 flex justify-between rounded-md">
              <div>
                <h1 className="text-xl leading-4 font-semibold mb-5">
                  Total No of Saccos
                </h1>
                <h1 className="text-4xl   font-bold text-slate-700 my-3 leading-none">
                  <span className="opacity-0">0</span>
                  {totalSHG}
                </h1>

                <div className="badge space-x-1 text-xl">
                  {" "}
                  <span>No.</span>
                  <i className="fas fa-arrow-right"></i>
                </div>
                <p className="text-md leading-4 mt-3">Saccos</p>
              </div>
              <div>
                <span className="text-lg text-white rounded-full flex items-center justify-center h-12 w-12 shrink-0 bg-success">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.37 7.87988H16.62"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.38 7.87988L6.13 8.62988L8.38 6.37988"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.37 14.8799H16.62"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.38 14.8799L6.13 15.6299L8.38 13.3799"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 21H14C19 21 21 19 21 14V8C21 3 19 1 14 1H8C3 1 1 3 1 8V14C1 19 3 21 8 21Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </Link>
          <Link
            to={"/affShgSavings"}
            className="hover:scale-105 transition ease-in-out delay-150"
          >
            <div className="widget-item bg-white p-6 flex justify-between rounded-md">
              <div>
                <div>
                  <h1 className="text-xl leading-4 font-semibold mb-5">
                    Total Savings
                  </h1>
                  <h1 className="text-4xl   font-bold text-slate-700 my-3 leading-none">
                    <span className="opacity-0">0</span>
                    {totalSavings}
                  </h1>

                  <div className="badge space-x-1 text-xl text-info bg-info/10">
                    {" "}
                    <span>Kwacha</span>
                    <i className="fas fa-arrow-right"></i>
                  </div>
                  <p className="text-md leading-4 mt-3">Savings</p>
                </div>
              </div>
              <div>
                <span className="text-lg text-white rounded-full flex items-center justify-center h-12 w-12 shrink-0 bg-info">
                  <svg
                    width="20"
                    height="22"
                    viewBox="0 0 20 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 21H19"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.59998 7.37988H2C1.45 7.37988 1 7.82988 1 8.37988V16.9999C1 17.5499 1.45 17.9999 2 17.9999H3.59998C4.14998 17.9999 4.59998 17.5499 4.59998 16.9999V8.37988C4.59998 7.82988 4.14998 7.37988 3.59998 7.37988Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.7999 4.18994H9.19995C8.64995 4.18994 8.19995 4.63994 8.19995 5.18994V16.9999C8.19995 17.5499 8.64995 17.9999 9.19995 17.9999H10.7999C11.3499 17.9999 11.7999 17.5499 11.7999 16.9999V5.18994C11.7999 4.63994 11.3499 4.18994 10.7999 4.18994Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.9999 1H16.3999C15.8499 1 15.3999 1.45 15.3999 2V17C15.3999 17.55 15.8499 18 16.3999 18H17.9999C18.5499 18 18.9999 17.55 18.9999 17V2C18.9999 1.45 18.5499 1 17.9999 1Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </Link>
          <Link
            to={"/affDashboard"}
            className="hover:scale-105 transition ease-in-out delay-150"
          >
            <div className="widget-item bg-white p-6 flex justify-between rounded-md">
              <div>
                <h1 className="text-xl leading-4 font-semibold mb-5">
                  Total Members
                </h1>
                <h1 className="text-4xl   font-bold text-slate-700 my-3 leading-none">
                  <span className="opacity-0">0</span>
                  {totalUsers}
                </h1>

                <div className="badge space-x-1 text-xl text-purple bg-purple/10">
                  {" "}
                  <span>No.</span>
                  <i className="fas fa-arrow-right"></i>
                </div>
                <p className="text-md leading-4 mt-3">Members</p>
              </div>

              <div>
                <span className="text-lg text-white rounded-full flex items-center justify-center h-12 w-12 shrink-0 bg-purple">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 6.16C16.94 6.15 16.87 6.15 16.81 6.16C15.43 6.11 14.33 4.98 14.33 3.58C14.33 2.15 15.48 1 16.91 1C18.34 1 19.49 2.16 19.49 3.58C19.48 4.98 18.38 6.11 17 6.16Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M15.9699 13.44C17.3399 13.67 18.8499 13.43 19.9099 12.72C21.3199 11.78 21.3199 10.24 19.9099 9.30004C18.8399 8.59004 17.3099 8.35003 15.9399 8.59003"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.96998 6.16C5.02998 6.15 5.09998 6.15 5.15998 6.16C6.53998 6.11 7.63998 4.98 7.63998 3.58C7.63998 2.15 6.48998 1 5.05998 1C3.62998 1 2.47998 2.16 2.47998 3.58C2.48998 4.98 3.58998 6.11 4.96998 6.16Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.99994 13.44C4.62994 13.67 3.11994 13.43 2.05994 12.72C0.649941 11.78 0.649941 10.24 2.05994 9.30004C3.12994 8.59004 4.65994 8.35003 6.02994 8.59003"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11 13.63C10.94 13.62 10.87 13.62 10.81 13.63C9.42996 13.58 8.32996 12.45 8.32996 11.05C8.32996 9.61997 9.47995 8.46997 10.91 8.46997C12.34 8.46997 13.49 9.62997 13.49 11.05C13.48 12.45 12.38 13.59 11 13.63Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.08997 16.78C6.67997 17.72 6.67997 19.26 8.08997 20.2C9.68997 21.27 12.31 21.27 13.91 20.2C15.32 19.26 15.32 17.72 13.91 16.78C12.32 15.72 9.68997 15.72 8.08997 16.78Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default AffDashboard;
